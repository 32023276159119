import { React, useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { axiosRequest } from '../../util/axiosRequest';
import { toast } from 'react-toastify';
import $ from "jquery"

const PetInsurance = (props) => {
    const { handelPetInsurance } = props;
    const [petName, setPetName] = useState('');
    const [petMobile, setPetMobile] = useState('');
    const [petCity, setPetCity] = useState('');
    const [otp, setOtp] = useState('');

    const handlePetNameChange = (e) => setPetName(e.target.value);
    const handlePetMobileChange = (e) => setPetMobile(e.target.value);
    const handlePetCityChange = (e) => setPetCity(e.target.value);
    const handleOtpChange = (e) => setOtp(e.target.value);

    const handleGetQuotes = () => {
        if (!petName || !petMobile || !petCity) {
            toast('Please fill out all the fields.');
            return;
        }
        axiosRequest('POST', 'web-apis', {type:"PetInsuranceForm",
            mobile: petMobile,
            name: petName,
            city: petCity,
        }).then(response => {
            const data = response.data;
            if (data.status) {
                toast(data.msg);
            } else {
                toast(data.msg);
            }
        }).catch(error => {
            toast('An error occurred while getting quotes.');
            console.error(error);
        });

        console.log('Getting Quotes:', { petName, petMobile, petCity });
    };


    useEffect(() => {

        $('.number-validation').on('keypress input', function(event){
            var pattForZip = /^[0-9\s]+$/;
                if(event.type == "keypress") {
            if(pattForZip.test(event.key)) {
            return true;
            }
            return false;
            }
            if(event.type == 'input') {
            var bufferValue = $(this).val().replace(/[^0-9\s]/gi, '').replace(/[_\s]/g, '');
            $(this).val(bufferValue);
            }
        });
    })

    const handleVerifyOtp = () => {
        if (!otp) {
            toast('Please enter the OTP.');
            return;
        }

        console.log('Verifying OTP:', otp);
        // Add your OTP verification logic here
    };
    return (
        <>
            <div className="main_custom_popup" id="quote_products">
                <div className="main_custom_popup_inner">
                    <div className="main_custom_popup_header">
                        <div className="header_title">Pet Insurance</div>
                        <div className="close_btn_pop" onClick={() => handelPetInsurance(false)}><img src={`${process.env.REACT_APP_S3IMAGEBASE_URL}close.svg`} alt="Close Button" title="Close Button" /></div>
                    </div>
                    <div className="main_custom_popup_inner_body">
                        <div className="cs_main_insurance_products_inner">
                            <div className="pat_insurance_outer">
                                <div className="pet_media">
                                    <img src={`${process.env.REACT_APP_S3IMAGEBASE_URL}pet-ins.webp`} alt="Pet insurance" title="Pet insurance" />
                                </div>
                                <div className="pet_content_media">
                                    <form className="lead-form" id="pet_insurance_form">
                                        <div className="input_group">
                                            <input
                                                type="text"
                                                name="petName"
                                                value={petName}
                                                autoComplete="anyrandomstring"
                                                onChange={handlePetNameChange}
                                                className="form_control"
                                                placeholder="Enter Name"
                                            />
                                        </div>
                                        <div className="input_group">
                                            <input
                                                type="text"
                                                name="petMobile"
                                                value={petMobile}
                                                autoComplete="anyrandomstring"
                                                onChange={handlePetMobileChange}
                                                className="number-validation form_control"
                                                minLength="10"
                                                maxLength="10"
                                                placeholder="Enter Mobile Number"
                                            />
                                        </div>
                                        <div className="input_group">
                                            <input
                                                type="text"
                                                name="petCity"
                                                value={petCity}
                                                autoComplete="anyrandomstring"
                                                onChange={handlePetCityChange}
                                                className="form_control"
                                                placeholder="Enter City"
                                            />
                                        </div>
                                        <div className="form-group otpUserDiv back-submit">
                                            <div className="row">
                                                <div className="col-sm-12 validate-input">
                                                    <div className="input_group">
                                                        <input
                                                            type="text"
                                                            id="otpUser"
                                                            placeholder="Enter OTP"
                                                            maxLength="6"
                                                            className="number-validation form_control"
                                                            value={otp}
                                                            onChange={handleOtpChange}
                                                        />
                                                        <button id="resnedOtpBtns" className="re_btn">Resend</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <button
                                            className="otpBtn subbutton"
                                            id="otpBtn"
                                            type="button"
                                            onClick={handleGetQuotes}
                                        >
                                            Get Quotes
                                        </button>
                                        <button
                                            className="VerifyOtpsPetins subbutton"
                                            id="VerifyOtpsPetins"
                                            type="button"
                                            onClick={handleVerifyOtp}
                                        >
                                            Verify OTP
                                        </button>
                                        <div className="check_box_list">
                                            <p className="bottom_description">By clicking on You, Agreed to our <Link to={"/privacypolicy"} target="_blank">Privacy Policy</Link> &amp;
                                                <Link to={"/terms-of-use"} target="_blank">Terms of use</Link> &amp; *Disclaimer
                                            </p>
                                            <div className="custom_whatapps">
                                                <label><img src={`${process.env.REACT_APP_S3IMAGEBASE_URL}whatsapp_icon.svg`} alt="Get Updates on WhatsApp" title="Get Updates on WhatsApp" />Get Updates on WhatsApp </label>
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked="" />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <div id="ShowSuccessMessage"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default PetInsurance