import React from 'react'
import Header from './Header';
import Footer from './Footer';



const DefaultLayout = ({children}) => {
  return (
    <div>
      <Header />
      {/* <Toastr/> */}
      {children}
      <Footer />
    </div>
  )
}

export default DefaultLayout