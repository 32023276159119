import React, { useEffect ,useState } from 'react';
import $ from "jquery"
import { Link } from "react-router-dom";
import PetInsurance from '../components/popups/PetInsurance';
import { Suspense } from 'react';

const Footer = () => {
    const [showPetIns, setShowHidePetInsurance] = useState(false);

    const handelPetInsurance = (show) => {
        setShowHidePetInsurance(show);
      }
    
    useEffect(() => {


        $(".mobile_menu_open").on('click', function () {
            $(".mobile_menu_div").show();
            $("body").addClass("overflow_hiddenn");
        });
        $(".close_menu_btn").on('click', function () {
            $(".mobile_menu_div").hide();
            $("body").removeClass("overflow_hiddenn");
        });
        $(".popover_btn").on('click', function () {
            $("popover_text").slideToggle();
        });
        $(this).find(".arrow").toggleClass('rotate');
        $(this).find(".arrow").toggleClass('rotate-reset');
        $(".cover_row_btn").on('click', function () {
            $(".cover_row").toggleClass("active_row");
        });
        $(".mobile_filter_btn").on('click', function () {
            $(".mobile_popup_quote").addClass("active_popup");
        });
        $(".mobile_menu_close_btn").on('click', function () {
            $(".mobile_popup_quote").removeClass("active_popup");
        });

        // $(".productPageclass").on("click",function(){
        //     setCookie("source", "product page", 30);
        // });

        // $(".MainMenu").on("click",function(){
        //     setCookie("source", "main menu", 30);
        // });

        window.onload = function () {
            checkWidth();
        };

        function checkWidth() {
            var windowWidth = window.innerWidth;

            if (windowWidth < 991) {
                $(".plus_down_minus_icon").on("click", function () {
                    $(this).text(function (i, text) {
                        return text === "+" ? "-" : "+";
                    })
                });

                $("#menu_open_btn").on("click", function () {
                    $("#man_menu_show_hide").slideToggle();
                    $("#menu_open_btn").toggleClass("close");
                    $("body").toggleClass("overflow_hiddenn");
                });

                $("#main_products_menu").on("click", function () {
                    $("#main_products_menu_list").slideToggle();
                });

                $("#health_insurance_btn").on("click", function () {
                    $("#health_insurance_list").slideToggle();
                });
                $("#life_insurance_btn").on("click", function () {
                    $("#life_insurance_list").slideToggle();
                });
                $("#car_insurance_btn").on("click", function () {
                    $("#car_insurance_list").slideToggle();
                });

                $("#bike_insurance_btn").on("click", function () {
                    $("#bike_insurance_list").slideToggle();
                });

                $("#travel_insurance_btn").on("click", function () {
                    $("#travel_insurance_list").slideToggle();
                });
                $("#property_insurance_btn").on("click", function () {
                    $("#property_insurance_list").slideToggle();
                });
                $("#commercial_insurance_btn").on("click", function () {
                    $("#commercial_insurance_list").slideToggle();
                });
                $("#term_insurance_btn").on("click", function () {
                    $("#term_insurance_list").slideToggle();
                });
                $("#marine_insurance_btn").on("click", function () {
                    $("#marine_insurance_list").slideToggle();
                });
                $("#pet_insurance_btn").on("click", function () {
                    $("#pet_insurance_list").slideToggle();
                });
                $("#advisor_menu").on("click", function () {
                    $("#advisor_menu_list").slideToggle();
                });

            } else {
                $(".shop-category>li>p").hover(function () {
                    $(".shop-category>li.active").removeClass("active");
                    $(".shop-mega-menu.hover").removeClass("hover");
                    $(this).parents(".shop-category-contain").attr("style", "");
                    $(this).addClass("active");
                    $(this).find(".shop-mega-menu").addClass("hover");
                    var mega_Menu_Height = $(".shop-category>li.active").find(".shop-mega-menu.hover").height();
                    var containerHeight = $(this).parents(".shop-category-contain").height();
                    if (mega_Menu_Height > containerHeight) {
                        $(this).parents(".shop-category-contain").height(mega_Menu_Height);
                        $(this).find(".shop-menu > ul").height(mega_Menu_Height);
                    } else {
                        $(this).parents(".shop-category-contain").height(containerHeight);
                        $(this).find(".shop-menu > ul").height(containerHeight);
                    }
                });
            }
        }

    }, []);

    return (
        <>
            <footer className="square_footer" role="contentinfo">
                <div className="container quote_hide_footer">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="footer_menu_row">
                                <div className="footer_menu_item">
                                    <ul>
                                        <li><Link to={"/"}>Health Insurance</Link></li>
                                        <li><Link to={"/"}>Life Insurance</Link></li>
                                        <li><Link to={"/prequotes/two-wheeler-insurance"}>Bike Insurance</Link></li>
                                        <li><Link to={"/prequotes/car-insurance-online"}>Car Insurance</Link></li>
                                        <li><Link to={"/"}>Marine Insurance</Link></li>
                                        <li><Link to={"/"}>Travel Insurance</Link></li>
                                        <li><a onClick={() => handelPetInsurance(true)}>Pet Insurance</a></li>
                                        <li><Link to={"/"}>Term Insurance</Link></li>                                        
                                        {showPetIns && <Suspense fallback={<div></div>}> <PetInsurance handelPetInsurance={handelPetInsurance} /> </Suspense>}
                                    </ul>
                                </div>
                            </div>
                            <div className="footer_menu_row2">
                                <ul>
                                    <li><Link to={"/company-profile"}>About Us</Link></li>
                                    <li><Link to={"/blog"}>Blog</Link></li>
                                    <li><Link to={"/privacypolicy"}>Privacy Policy</Link></li>
                                    <li><Link to={"/grievance-redress-policy"}>Grievance Redress Policy</Link></li>
                                    <li><Link to={"/life-at-square-insurance"}>Career</Link></li>
                                    <li><Link to={"/corporate-insurance"}>Corporate Insurance</Link></li>
                                    <li><Link to={"/contactus"}>Contact Us</Link></li>
                                    <li><Link to={"/"}>Tools</Link></li>
                                    <li><Link to={"/annual-return"}>Annual Return</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer_full_black">
                    <div className="container quote_hide_footer">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="get_in_touch">
                                    <div className="get_in_touch_title">Get In Touch</div>
                                    <div className="get_in_touch_row">
                                        <div className="address_row">
                                            <span><img src={`${process.env.REACT_APP_S3IMAGEBASE_URL}social_media/pin.svg`} alt="Square Insurance Brokers Pvt. Ltd." title="Square Insurance Brokers Pvt. Ltd." loading="lazy" /></span>
                                            <p>
                                                <b>Square Insurance Brokers Pvt. Ltd.</b>
                                                <small>506, 5th Floor, V-Jai City Point, Ahinsha Circle, C-Scheme, Jaipur (Raj.)-302001</small>
                                            </p>
                                        </div>
                                        <div className="contact_social">
                                            <div className="contact_details">
                                                <Link to="/" className="bdr-none"><img src={`${process.env.REACT_APP_S3IMAGEBASE_URL}social_media/world.svg`} alt="Square Insurance - Website" loading="lazy" />www.squareinsurance.in</Link>
                                                <Link to={"tel:18001205430"}><img src={`${process.env.REACT_APP_S3IMAGEBASE_URL}social_media/phone_call.svg`} alt="Toll-Free Number of Square Insurance" loading="lazy" />18001205430</Link>
                                                <Link to={"mailto:info@squareinsurance.in"}><img src={`${process.env.REACT_APP_S3IMAGEBASE_URL}social_media/email.svg`} alt="Email Address Of Square Insurance" loading="lazy" />info@squareinsurance.in</Link>
                                            </div>
                                            <div className="social_media">
                                                <h3>Follow Us</h3>
                                                <span>
                                                    <Link to={"https://www.facebook.com/squareinsurance/"} title="facebook" rel="noopener noreferrer nofollow" target="_blank"><img src={`${process.env.REACT_APP_S3IMAGEBASE_URL}social_media/facebook.svg`} alt="facebook" loading="lazy" /></Link>
                                                    <Link to={"https://www.instagram.com/insurancesquare/"} title="instagram" rel="noopener noreferrer nofollow" target="_blank"><img src={`${process.env.REACT_APP_S3IMAGEBASE_URL}social_media/instagram.svg`} alt="instagram" loading="lazy" /></Link>
                                                    <Link to={"https://in.pinterest.com/squareinsurancebroker/"} title="Pinterest" rel="noopener noreferrer nofollow" target="_blank"><img src={`${process.env.REACT_APP_S3IMAGEBASE_URL}social_media/pinterest.svg`} alt="Pinterest" loading="lazy" /></Link>
                                                    <Link to={"https://www.youtube.com/@squareinsurancebrokerspvtl3975"} title="Youtube" rel="noopener noreferrer nofollow" target="_blank"><img src={`${process.env.REACT_APP_S3IMAGEBASE_URL}social_media/youtube.svg`} alt="Youtube" loading="lazy" /></Link>
                                                    <Link to={"https://twitter.com/sibpl_gic"} title="Twitter" rel="noopener noreferrer nofollow" target="_blank"><img src={`${process.env.REACT_APP_S3IMAGEBASE_URL}social_media/twitter.svg`} alt="Twitter" loading="lazy" /></Link>
                                                    <Link to={"https://in.linkedin.com/company/square-insurance-brokers"} title="linkedin" rel="noopener noreferrer nofollow" target="_blank"><img src={`${process.env.REACT_APP_S3IMAGEBASE_URL}social_media/linkedin_logo.svg`} alt="linkedin" loading="lazy" /></Link>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="footer_address">
                                    <span>IRDA Approved Branches</span> -
                                    <Link rel="noopener noreferrer nofollow" target="_blank" to={"https://www.google.com/search?q=Square+Insurance+Brokers+Pvt.+Ltd&amp;stick=H4sIAAAAAAAA_-NgU1I1qDC2NEtJMjEwtEhNMjYyMjK2MqhIMzJNMkg2SUpJTkwxNUtKXcSqGFxYmliUquCZV1xalJiXnKrgVJSfnVpUrBBQVqKn4FOSAgBzYQOGTQAAAA&amp;hl=en&amp;mat=CTzXf_Qi9uBPElYBNqvzOhPd_bEYuRc-EjwR9ykSRHvw6ksPxfP3_NBOFsrBSuk3TytfCBy-uKYsrew2431TIEJAz3vK9r5dCommE4GPtLJ-5su4GWKPDEJ35JBbChOw_w&amp;authuser=0"}>Jaipur</Link>
                                    <Link rel="noopener noreferrer nofollow" target="_blank" to={"https://g.page/r/CbWG_AK58Z_2EAI/review"}>Mumbai</Link>
                                    <Link rel="noopener noreferrer nofollow" target="_blank" to={"https://www.google.com/localservices/prolist?spp=Cg0vZy8xMXZycjVieWh3&scp=CgAaIVNxdWFyZSBJbnN1cmFuY2UgQnJva2VycyBQdnQuIEx0ZCohU3F1YXJlIEluc3VyYW5jZSBCcm9rZXJzIFB2dC4gTHRk&q=Square+Insurance+Brokers+Pvt.+Ltd&src=2&slp=UhUIARIREg8iDS9nLzExdnJyNWJ5aHc"}>Pune</Link>
                                    <Link rel="noopener noreferrer nofollow" target="_blank" to={"https://g.page/r/CSZR_sAg5UQKEAI/review"}>Ahmedabad</Link>
                                    <Link rel="noopener noreferrer nofollow" target="_blank" to={"https://www.google.com/search?q=Square+Insurance+Brokers+Pvt.+Ltd.&stick=H4sIAAAAAAAA_-NgU1I1qDBOSkw1NE9NSkw2MEs0TkqxAgoZGqQlmxkaWlgmGZgYWZosYlUKLixNLEpV8MwrLi1KzEtOVXAqys9OLSpWCCgr0VPwKUnRAwD7kYbxTgAAAA&hl=en-GB&mat=CVkuL05gHZxyElcBl7_Ll4JpdXU-eItxP77bVcvdNKCKzgKgQei1j7Mh4qRXeEiv8-gV3_kv-O6Th1BOrCtRSc2CfIsjY2UX2b8HmGb6h9r08cxluQa2TiIQlR5vNE97fcw&authuser=0"}>Bangalore</Link>
                                    <Link rel="noopener noreferrer nofollow" target="_blank" to={"https://g.page/r/Cd72TdWFoUS_EAI/review "}>Dehradun</Link>
                                    <Link rel="noopener noreferrer nofollow" target="_blank" to={"https://www.google.com/search?q=Square+Insurance+Brokers+Pvt.+Ltd.&stick=H4sIAAAAAAAA_-NgU1I1qDC2NEhLTTE2M0yxNDMwNDO1MqgwM7ZMTTYxS00xsDAzN7MwWcSqFFxYmliUquCZV1xalJiXnKrgVJSfnVpUrBBQVqKn4FOSogcA3lFCR04AAAA&hl=en&mat=CSXwZYhnfBQLElYBl7_LlxZTwc0mLejIbEwR7UcjihhDEKfGNsnmfHsBTfevquOQwmTPLlbfHkAEJ-8Vt1qp19UmuFQ_-uUGiBBTRYeHXhFU-gGsT9zhnoK_NPsN8AQqPQ&authuser=0"}>Chandigarh</Link >
                                    <Link rel="noopener noreferrer nofollow" target="_blank" to={"https://www.google.com/search?q=Square+Insurance+Brokers+Pvt.+Ltd&stick=H4sIAAAAAAAA_-NgU1I1qDC2TDNJNTRNNkpJMzRMMkuyMqgwSTQ1sbA0MkxNSbMwMjBLWcSqGFxYmliUquCZV1xalJiXnKrgVJSfnVpUrBBQVqKn4FOSAgA0mRLPTQAAAA&hl=en&mat=CRG-kzTv8Ot3ElcBl7_Ll-iwVA5gq2Rd3CUj3RT4OD4GAOVHDPE7Ghk7EEEjX7OK1CrhXLtq28imjEjOkwO5BO3GpfwUUhW-mhG3LkYh8FHammfdjb0Zs0JLa9o502GW1-A&authuser=0&dlnr=1&sei=0tAwZeLpB6q64-EPlO-nwAQ#dlnr=1"}>Ranchi</Link>
                                    <Link rel="noopener noreferrer nofollow" target="_blank" to={"https://g.co/kgs/KZr9tj"}>Bikaner</Link>
                                    <Link rel="noopener noreferrer nofollow" target="_blank" to={"https://www.google.com/search?q=Square+Insurance+Brokers+Pvt.+Ltd.&stick=H4sIAAAAAAAA_-NgU1I1qDC2NDRONDQwMk2ztExJMjK0MqhINUhJTklNNUxOMk82MkhJW8SqFFxYmliUquCZV1xalJiXnKrgVJSfnVpUrBBQVqKn4FOSogcA0ysvWk4AAAA&hl=en&mat=CU3er1r-nAYrElcBl7_Ll7iNZluDetQBGT27uB3hKKPwQCEB3UIsZs0dY1O2qZyxiDcfX2GmfQ96VgCmwbHfKq3R-IcWk3YiJvKs3sd62pn3Hj1ooKtNSewhr6mcuAx5ZSo&authuser=0"}>Rajgarh</Link>
                                    <Link rel="noopener noreferrer nofollow" target="_blank" to={"https://g.page/r/CajaNLL4AgvOEAI/review"}>Alwar</Link>
                                    <Link rel="noopener noreferrer nofollow" target="_blank" to={"https://g.page/r/CdR_I5TMpM_1EAI/review"}>Jhunjhunu</Link>
                                    <Link rel="noopener noreferrer nofollow" target="_blank" to={"https://www.google.com/search?q=Square+Insurance+Brokers+Pvt.+Ltd&stick=H4sIAAAAAAAA_-NgU1I1qDC2NEuzTDKwMDdJtjBNMzS2MqhINE8zTjVJSkkyTjI0MEk0XMSqGFxYmliUquCZV1xalJiXnKrgVJSfnVpUrBBQVqKn4FOSAgB4SZqETQAAAA&hl=en&mat=CcEgE9hvYN5PElYBl7_Ll7ab7zSTWBHR63mIYWOb1PPO1UJzy3Za0ciY7zpb9TXs5Hf4-DlBNCvFYgVqL-7HIx5CHnsHh2rtpRyhcG8ZH2GH25dNGg6k5dX-fziMMxbTBw&authuser=0"}>Kota</Link>
                                    <Link rel="noopener noreferrer nofollow" target="_blank" to={"https://www.google.com/search?q=Square+Insurance+brokers+Pvt.+Ltd&stick=H4sIAAAAAAAA_-NgU1I1qDC2NEtOSjJOSzUwM01LMk2zMqhISTQCsowMzQyMDFJSzU0XsSoGF5YmFqUqeOYVlxYl5iWnKiQV5WenFhUrBJSV6Cn4lKQAAHmc5_xNAAAA&hl=en-GB&mat=CVBYOJ6lJrIGElcBl7_Ll38kEIBJn4J8bubSU73iH3qR7qQMeCR6zZkolY_2EYc-SSVj7uaC5Qob7Nbe_VGnEF6HgXzo-yZuqdBHtEVOKlLd0RlMHqiUkqRDANuRpV0QjbQ&authuser=0"}>Sikar</Link>
                                </div >
                            </div >
                        </div >
                    </div >
                    <div className="copyright_block">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <p>Square Insurance Brokers Pvt. Ltd. | Reg. Office - 506, 5th Floor, V-Jai City Point, Ahinsha Circle, C-Scheme, Jaipur (Raj.)-302001 | IRDAI Direct Insurance Broker (Life & General) | License No.- 606 | Valid Till - 04/07/2026 (Renewable) Code No. -IRDA/DB697/17 | CIN NO. - U66000RJ2016PTC056324 | ISO 9001:2015 Reg. No. -IN118260A | IBAI Membership No.-519 Standard T&C Apply* For more details on risk factors, terms, and conditions, please read the sales brochure carefully before concluding a sale.Visitors are hereby informed that their information submitted on the website may be shared with insurers. Product information is authentic and solely based on the information received from the insurers.<br/>copyright © 2019-2024  Square insurance All Rights Reserved</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </footer >
        </>
    );
};

export default Footer;
