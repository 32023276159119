import React, { lazy } from 'react';
import DefaultLayout from "../common-layouts/DefaultLayout";
import QuoteLayout from "../common-layouts/QuoteLayout";

// Lazy load components
const Home = lazy(() => import("../components/home/Home"));
const About = lazy(() => import("../components/about/About"));
const MainPolicies = lazy(() => import("../components/policies/MainPolicies"));
const TeamDetails = lazy(() => import("../components/about/TeamDetails"));
const Claim = lazy(() => import("../components/claim/Claim"));
const InsuranceAudit = lazy(() => import("../components/claim/InsuranceAudit"));
const BecomeAPosp = lazy(() => import("../components/agentposp/BecomeAPosp"));
const BikeInsurance = lazy(() => import("../components/insuranceproducts/BikeInsurance"));
const CarInsurance = lazy(() => import("../components/insuranceproducts/CarInsurance"));
const LifeInsurance = lazy(() => import("../components/insuranceproducts/LifeInsurance"));
const TravelInsurance = lazy(() => import("../components/insuranceproducts/TravelInsurance"));
const HealthInsurance = lazy(() => import("../components/insuranceproducts/HealthInsurance"));
const PropertyInsurance = lazy(() => import("../components/insuranceproducts/PropertyInsurance"));
const MarineInsurance = lazy(() => import("../components/insuranceproducts/MarineInsurance"));
const ContactUs = lazy(() => import("../components/otherpage/ContactUs"));
const AnnualReturn = lazy(() => import("../components/otherpage/AnnualReturn"));
const CorporateInsurance = lazy(() => import("../components/otherpage/CorporateInsurance"));
const Reviews = lazy(() => import("../components/insuranceproducts/Reviews"));
const Blog = lazy(() => import("../components/blog/Blog"));
const BlogList = lazy(() => import("../components/blog/BlogList"));
const BlogDetails = lazy(() => import("../components/blog/BlogDetails"));
const HomeInsurance = lazy(() => import("../components/homeinsurance/HomeInsurance"));
const HealthPrequotes = lazy(() => import("../components/health/HealthPrequotes"));
const HealthQuotes = lazy(() => import("../components/health/HealthQuotes"));
const NotFound = lazy(() => import("../components/NotFound"));
const Prequotes = lazy(() => import("../components/motor/Prequotes"));
const Quotes = lazy(() => import("../components/motor/Quotes"));
const Proposal = lazy(() => import("../components/motor/Proposal"));
const Review = lazy(() => import("../components/motor/Review"));
const PaymentSuccess = lazy(() => import("../components/payments_pages/PaymentSuccess"));
const PaymentFailed = lazy(() => import("../components/payments_pages/PaymentFailed"));
const HealthProposal = lazy(() => import("../components/health/HealthProposal"));
const HealthReviews = lazy(() => import("../components/health/HealthReviews"));
const PospForm = lazy(() => import("../components/agentposp/PospForm"));
const Advisorsearch = lazy(() => import("../components/advisor/Advisorsearch"));
const AdvisorList = lazy(() => import("../components/advisor/AdvisorList"));
const Tools = lazy(() => import("../components/otherpage/Tools"));

export const allRoute = [
  { path: "/", layout: DefaultLayout, component: Home },
  { path: "/employee", layout: DefaultLayout, component: Home },
  { path: "/company-profile", layout: DefaultLayout, component: About, exact: true },
  { path: "/team-square", layout: DefaultLayout, component: About, exact: true },
  { path: "/team-square-details", layout: DefaultLayout, component: TeamDetails, exact: true },
  { path: "/life-at-square-insurance", layout: DefaultLayout, component: About, exact: true },
  { path: "/our-gallery", layout: DefaultLayout, component: About, exact: true },
  { path: "/why-square-insurance", layout: DefaultLayout, component: About, exact: true },
  { path: "/advisory-board", layout: DefaultLayout, component: About, exact: true },
  { path: "/support-directory", layout: DefaultLayout, component: About, exact: true },
  { path: "/prequotes/two-wheeler-insurance", layout: DefaultLayout, component: Prequotes, exact: true, type: 1 },
  { path: "/prequotes/car-insurance-online", layout: DefaultLayout, component: Prequotes, exact: true, type: 2 },
  { path: "/prequotes/pcv-insurance/:vtype?", layout: DefaultLayout, component: Prequotes, exact: true, type: 3 },
  { path: "/prequotes/goods-carring-vehicle-insurance/:vtype?/:ctype?", layout: DefaultLayout, component: Prequotes, exact: true, type: 4 },
  { path: "/prequotes/miscellaneous-insurance/:ctype?", layout: DefaultLayout, component: Prequotes, exact: true, type: 5 },
  { path: "/quotes/:id", layout: QuoteLayout, component: Quotes, exact: true },
  { path: "/proposal/:id", layout: QuoteLayout, component: Proposal, exact: true },
  { path: "/review/:id", layout: DefaultLayout, component: Review, exact: true },
  { path: "/payment-success/:id", layout: DefaultLayout, component: PaymentSuccess, exact: true },
  { path: "/payment-failed/:id", layout: DefaultLayout, component: PaymentFailed, exact: true },
  { path: "/privacypolicy", layout: DefaultLayout, component: MainPolicies, exact: true },
  { path: "/grievance-redress-policy", layout: DefaultLayout, component: MainPolicies, exact: true },
  { path: "/license", layout: DefaultLayout, component: MainPolicies, exact: true },
  { path: "/terms", layout: DefaultLayout, component: MainPolicies, exact: true },
  { path: "/refund-policy-and-cancellation", layout: DefaultLayout, component: MainPolicies, exact: true },
  { path: "/isnp", layout: DefaultLayout, component: MainPolicies, exact: true },
  { path: "/terms-of-use", layout: DefaultLayout, component: MainPolicies, exact: true },
  { path: "/raise-claim", layout: DefaultLayout, component: Claim, exact: true },
  { path: "/insurance-portfolio-analysis", layout: DefaultLayout, component: InsuranceAudit, exact: true },
  { path: "/become-pos", layout: DefaultLayout, component: BecomeAPosp, exact: true },
  { path: "/bike-insurance-policy", layout: DefaultLayout, component: BikeInsurance, exact: true },
  { path: "/car-insurance-policy", layout: DefaultLayout, component: CarInsurance, exact: true },
  { path: "/health-insurance", layout: DefaultLayout, component: HealthInsurance, exact: true },
  { path: "/life-insurance", layout: DefaultLayout, component: LifeInsurance, exact: true },
  { path: "/marine-insurance", layout: DefaultLayout, component: MarineInsurance, exact: true },
  { path: "/property-insurance", layout: DefaultLayout, component: PropertyInsurance, exact: true },
  { path: "/travel-insurance", layout: DefaultLayout, component: TravelInsurance, exact: true },
  { path: "/contactus", layout: DefaultLayout, component: ContactUs, exact: true },
  { path: "/annual-return", layout: DefaultLayout, component: AnnualReturn, exact: true },
  { path: "/corporate-insurance", layout: DefaultLayout, component: CorporateInsurance, exact: true },
  { path: "/reviews", layout: DefaultLayout, component: Reviews, exact: true },
  { path: "/blog", layout: DefaultLayout, component: Blog, exact: true },
  { path: "/blog/:slug", layout: DefaultLayout, component: BlogList, exact: true },
  { path: "/blog/:pagename/:slug", layout: DefaultLayout, component: BlogDetails, exact: true },
  { path: "/home-insurance", layout: DefaultLayout, component: HomeInsurance, exact: true },
  { path: "/prequotes/health-insurance-online", layout: DefaultLayout, component: HealthPrequotes, exact: true },
  { path: "/quote-health/:id", layout: QuoteLayout, component: HealthQuotes, exact: true },
  { path: "/health/proposal/:id", layout: QuoteLayout, component: HealthProposal, exact : true },
  { path: "/health/review/:id", layout: QuoteLayout, component: HealthReviews, exact : true },
  { path: "/agents", layout: DefaultLayout, component: PospForm, exact : true },
  { path: "/find-insurance-advisor", layout: DefaultLayout, component: Advisorsearch, exact : true  },
  { path: "/insurance-advisor-list", layout: DefaultLayout, component: AdvisorList, exact : true  },
  { path: "/tools", layout: DefaultLayout, component: Tools, exact : true  },
  { path: "*", layout: DefaultLayout, component: NotFound }
];