import axios from "axios";
import { BASE_URL, BASE_NODE_URL, COMMON_API_BASE_URL } from "./globle_constant";

export const axiosRequest = (method, path, data={}, other={}) => {

    return new Promise((resolve, reject) => {

        let token   = (other.token) ? other.token : false;
        let node    = (other.node) ? other.node : false;
        let common    = (other.common) ? other.common : false;
        
        const apiurl = (node) ? BASE_NODE_URL : ((common) ? COMMON_API_BASE_URL : BASE_URL);
        const url = `${apiurl}${path}`;

        let headers = {
            /* 'Content-Type': 'application/json' */
            'Content-Type': 'multipart/form-data'
        };

        if(token){
            headers['Authorization'] = 'Bearer your_token';
        }

        if(node || common){
            headers['Content-Type'] = 'application/json';
        }

        let is_logged = localStorage.getItem("is_logged");

        if(is_logged){
            let login_name = localStorage.getItem("login_name");
            let login_data = localStorage.getItem("login_data");
            let login_type = localStorage.getItem("login_type");
            data['is_logged'] = is_logged;
            if(login_name && login_type && login_data){
                data['login_name'] = login_name;
                data['login_type'] = login_type;
                data['login_data'] = login_data;
            }
        }else{
            data['is_logged'] = false; 
        }

        data['platform_new'] = "web";

        /* if (csrfToken) {
            headers['X-CSRF-Token'] = csrfToken;
          } */

       /*  if(csrfToken && method.toUpperCase() == "POST"){
            headers['X-CSRF-Token'] = csrfToken;
        } */
 
        axios({
            method: method,
            url: url,
            headers: headers,
            data: data
        }).then(response => {
            resolve(response.data); // Resolve with the response data
        }).catch(error => {
            reject(error); // Reject with the error
        });
    });
};


export const axiosFullUrlRequest = (method, url, data={}) => {
    return new Promise((resolve, reject) => {

        let headers = {
            'Content-Type': 'application/json'
            /* 'Content-Type': 'multipart/form-data' */
        };

        axios({
            method: method,
            url: url,
            headers: headers,
            data: data
        }).then(response => {
            resolve(response.data); // Resolve with the response data
        }).catch(error => {
            reject(error); // Reject with the error
        });
    });
};


export const axiosNodeRequest = (method, url, token=false) => {
    return new Promise((resolve, reject) => {

        const headers = {
            /* 'Content-Type': 'application/json' */
            'Content-Type': 'multipart/form-data'
        };

        if(token){
            headers['Authorization'] = 'Bearer your_token';
        }

        axios({
            method: method,
            url: url,
            headers: headers
        }).then(response => {
            resolve(response.data); // Resolve with the response data
        }).catch(error => {
            reject(error); // Reject with the error
        });
    });
};
