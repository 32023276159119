import { React, useEffect,useState } from 'react';
import { Link,useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import $ from "jquery"
import Login from '../components/popups/Login';
import OurDetails from '../components/popups/OurDetails';

const Header = () => {
    const { pathname } = useLocation();
    const { is_logged,login_name,login_type } = useSelector(state => state.auth);
    const [showLogin, setShowHideLogin] = useState(false);
    const [showHeaderDetsils, setShowHideHeaderDetails] = useState(false);
  
    const handelLoginPop = (show) => {
        setShowHideLogin(show);
    }
    // const handelHeaderDetails = (show) => {
    //     setShowHideHeaderDetails(show);
    // }

    const handelHeaderDetails = (show) => {
        // if (show) {
        //     $('body').addClass('overflow_hiddenn');
        // } else {
        //     $('body').removeClass('overflow_hiddenn');
        // }
        setShowHideHeaderDetails(show);
    }

    useEffect(() =>{
        handelHeaderDetails(false);
    }, [pathname]);

  
    useEffect(() => {      



        $(".mobile_menu_open").on('click',function(){
            $(".mobile_menu_div").show();
            $("body").addClass("overflow_hiddenn");
        });
        $(".close_menu_btn").on('click',function(){
            $(".mobile_menu_div").hide();
            $("body").removeClass("overflow_hiddenn");
        });
        $(".popover_btn").on('click',function(){
            $("popover_text").slideToggle();
        });
        $(this).find(".arrow").toggleClass('rotate');
        $(this).find(".arrow").toggleClass('rotate-reset');
        $(".cover_row_btn").on('click',function(){
            $(".cover_row").toggleClass("active_row");
        });
        $(".mobile_filter_btn").on('click',function(){
            $(".mobile_popup_quote").addClass("active_popup");
        });
        $(".mobile_menu_close_btn").on('click',function(){
            $(".mobile_popup_quote").removeClass("active_popup");
        });


        window.onload = function() {
            checkWidth();
        };

        function checkWidth() {
            var windowWidth = window.innerWidth;
        
            if (windowWidth < 991) {  
                $(".plus_down_minus_icon").on("click",function(){       
                    $(this).text(function(i, text){
                        return text === "+" ? "-" : "+";
                    })
                });

                $("#menu_open_btn").on("click",function(){
                    $("#man_menu_show_hide").slideToggle();
                    $("#menu_open_btn").toggleClass("close");  
                    $("body").toggleClass("overflow_hiddenn");                  
                });
        
                $("#main_products_menu").on("click",function(){
                    $("#main_products_menu_list").slideToggle();
                });
        
                $("#health_insurance_btn").on("click",function(){
                    $("#health_insurance_list").slideToggle();
                });
                $("#life_insurance_btn").on("click",function(){
                    $("#life_insurance_list").slideToggle();
                });
                $("#car_insurance_btn").on("click",function(){
                    $("#car_insurance_list").slideToggle();
                });

                $("#bike_insurance_btn").on("click",function(){
                    $("#bike_insurance_list").slideToggle();
                });
               
                $("#travel_insurance_btn").on("click",function(){
                    $("#travel_insurance_list").slideToggle();
                });
                $("#property_insurance_btn").on("click",function(){
                    $("#property_insurance_list").slideToggle();
                });
                $("#commercial_insurance_btn").on("click",function(){
                    $("#commercial_insurance_list").slideToggle();
                });
                $("#term_insurance_btn").on("click",function(){
                    $("#term_insurance_list").slideToggle();
                });
                $("#marine_insurance_btn").on("click",function(){
                    $("#marine_insurance_list").slideToggle();
                });
                $("#pet_insurance_btn").on("click",function(){
                    $("#pet_insurance_list").slideToggle();
                });
                $("#advisor_menu").on("click",function(){
                    $("#advisor_menu_list").slideToggle();
                });

            } else {
                $(".shop-category>li>p").hover(function () {
                    $(".shop-category>li.active").removeClass("active");
                    $(".shop-mega-menu.hover").removeClass("hover");
                    $(this).parents(".shop-category-contain").attr("style", "");
                    $(this).addClass("active");
                    $(this).find(".shop-mega-menu").addClass("hover");
                    var mega_Menu_Height = $(".shop-category>li.active").find(".shop-mega-menu.hover").height();
                    var containerHeight = $(this).parents(".shop-category-contain").height();
                    if (mega_Menu_Height > containerHeight) {
                        $(this).parents(".shop-category-contain").height(mega_Menu_Height);
                        $(this).find(".shop-menu > ul").height(mega_Menu_Height);
                    } else {
                        $(this).parents(".shop-category-contain").height(containerHeight);
                        $(this).find(".shop-menu > ul").height(containerHeight);
                    }
                });
            }
        }

     }, []);

    return (
        <>
            <header className="square_header" role="banner">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-12">
                            <div className="header_static">
                                <div className="logo_section">
                                    <Link to={"/"}>
                                        <img src={`${process.env.REACT_APP_S3IMAGEBASE_URL}square_full_logo.svg`} alt="Square Insurance - Logo" loading="lazy" />
                                    </Link>
                                </div>
                                <div className="main_menu_inner">
                                    <label id="menu_open_btn" className="menu_open_btn">
                                        <span className="main-menu-btn-icon"></span>
                                    </label>
                                    <div className="main_menu">
                                        <nav id="man_menu_show_hide">
                                            <ul>
                                                <li>
                                                    <Link id="main_products_menu">Insurance Products <small className="down_icon"></small></Link>
                                                    <ul className="mega_menu" id="main_products_menu_list">
                                                        <div className="shop-menu">
                                                            <ul className="shop-category">
                                                                <li className="active">
                                                                    <p><Link to={"/health-insurance"} title="Health Insurance"><img src={`${process.env.REACT_APP_S3IMAGEBASE_URL}health_menu_icon.svg`} alt="Health Insurance" loading="lazy" />Health Insurance</Link><small className="plus_down_minus_icon" id="health_insurance_btn">+</small></p>
                                                                    <div className="shop-mega-menu hover block-view" id="health_insurance_list">
                                                                        <div className="mega-menu-block">
                                                                            <div className="mega-menu-part">
                                                                                <h2>Best Health Insurance Plans</h2>
                                                                                <p>Health insurance is not a new term in today’s world. Above this, the global pandemic has also knocked on our door to secure ourselves from unwanted situations. This is where healthcare insurance comes into play. This is a mutual agreement between a policyholder and insurance company to offer compensation as a medical expense in case you meet with an accident or get hospitalized.</p>
                                                                                <div className="megamenu-btn-align">
                                                                                    <Link to={"/"}>View Article</Link>
                                                                                    <Link className="MainMenu" href={"/"}>Get Quotes</Link>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <p><Link to={"/life-insurance"} title="Life Insurance"><img src={`${process.env.REACT_APP_S3IMAGEBASE_URL}life_menu_icon.svg`} alt="Life Insurance" loading="lazy" />Life Insurance</Link><small className="plus_down_minus_icon" id="life_insurance_btn">+</small></p>
                                                                    <div className="shop-mega-menu" id="life_insurance_list">
                                                                        <div className="mega-menu-block">
                                                                            <div className="mega-menu-part">
                                                                                <h2>Best Life Insurance Online</h2>
                                                                                <p>Living a tension-free life with your beloved family is the most precious thing everybody wants. People do everything to take care of the emotional and financial needs of their loved ones. Everybody wishes that their family members stay happy, protected, and financially secure throughout their life, and tensions stay far away if anything unfavorable happens.</p>
                                                                                <div className="megamenu-btn-align">
                                                                                    <Link to={"/"}>View Article</Link>
                                                                                    <Link className="MainMenu" href={"/"}>Get Quotes</Link>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <p><Link to={"/car-insurance-policy"} title="Motor Insurance"><img src={`${process.env.REACT_APP_S3IMAGEBASE_URL}car_menu_icon.svg`} alt="Car Insurance" loading="lazy" />Car Insurance</Link><small className="plus_down_minus_icon" id="car_insurance_btn">+</small></p>
                                                                    <div className="shop-mega-menu" id="car_insurance_list">
                                                                        <div className="mega-menu-block">
                                                                            <div className="mega-menu-part">
                                                                                <h2>Car Insurance Online</h2>
                                                                                <p>Car insurance is a policy that protects the financial interests of your vehicle in the occurrence of any unforeseen incidents such as theft, accidents, natural or man-made disasters. A car insurance policy also provides you a financial shield in case of any damage to a third party person or property.</p>
                                                                                <div className="megamenu-btn-align">
                                                                                    <Link to={"/"}>View Article</Link>
                                                                                    <Link className="MainMenu" href={"/"}>Get Quotes</Link>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <p><Link to={"/bike-insurance-policy"} title="Bike Insurance"><img src={`${process.env.REACT_APP_S3IMAGEBASE_URL}bike_menu_icon.svg`} alt="Bike Insurance" loading="lazy" />Bike Insurance</Link><small className="plus_down_minus_icon" id="bike_insurance_btn">+</small></p>
                                                                    <div className="shop-mega-menu" id="bike_insurance_list">
                                                                        <div className="mega-menu-block">
                                                                            <div className="mega-menu-part">
                                                                                <h2>Bike Insurance Online</h2>
                                                                                <p>The law regarding motorcycle insurance in India requires the purchase of a Third-Party Liability Bike Insurance Policy at the very least. These are the requirements outlined in the Indian Motor Vehicles Act. The law requires you to pay monetary penalties if you violate it. Failure to comply can even land you in jail! Moreover, an uninsured bike accident can result in severe legal consequences.</p>
                                                                                <div className="megamenu-btn-align">
                                                                                    <Link to={"/"}>View Article</Link>
                                                                                    <Link className="MainMenu" href={"/"}>Get Quotes</Link>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <p><Link to={"/travel-insurance"} title="travel Insurance"><img src={`${process.env.REACT_APP_S3IMAGEBASE_URL}travel_menu_icon.svg`} alt="Travel Insurance" loading="lazy" />Travel Insurance</Link><small className="plus_down_minus_icon" id="travel_insurance_btn">+</small></p>
                                                                    <div className="shop-mega-menu" id="travel_insurance_list">
                                                                        <div className="mega-menu-block">
                                                                            <div className="mega-menu-part">
                                                                                <h2>Travel Insurance Online</h2>
                                                                                <p>An insurance policy that offers financial compensation to the policyholder if something goes wrong while on a trip is known as travel insurance. Vacations, business trips, and educational trips are all covered by travel insurance plans.</p>
                                                                                <div className="megamenu-btn-align">
                                                                                    <Link to={"/"}>View Article</Link>
                                                                                    <Link className="MainMenu" href={"/"}>Get Quotes</Link>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <p><Link to={"/marine-insurance"} title="marine insurance"><img src={`${process.env.REACT_APP_S3IMAGEBASE_URL}Marine_menu_icon.svg`} alt="Marine Insurance" loading="lazy" />Marine Insurance</Link><small className="plus_down_minus_icon" id="marine_insurance_btn">+</small></p>
                                                                    <div className="shop-mega-menu" id="marine_insurance_list">
                                                                        <div className="mega-menu-block">
                                                                            <div className="mega-menu-part">
                                                                                <h2>Marine Insurance Online</h2>
                                                                                <p>Business is no longer constrained by borders and goods are shipped around the world. It's very crucial to protect these goods from various possible mishaps. Marine Insurance Policy is one of the best cargo insurance covers, which will help you protect your valuable cargo literally anywhere in the world. Our insurance policy will provide full cover against damage or loss to cargo sent by sea, road, rail or air.</p>
                                                                                <div className="megamenu-btn-align">
                                                                                    <Link to={"/"}>View Article</Link>
                                                                                    <Link className="MainMenu" href={"/"}>Get Quotes</Link>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <Link id="advisor_menu">Insurance Advisors <small className="down_icon"></small></Link>
                                                    <ul className="sub_menu pages" id="advisor_menu_list">
                                                        <li><Link to={"/"} title="Insurance Advisors in">Insurance Advisors in Jaipur</Link></li>
                                                    </ul>
                                                </li>
                                                <li><Link to={"/raise-claim"} title="Claim">Claim</Link></li>
                                                <li><Link to={"/support-directory"} title="Support Directory">Support</Link></li>
                                                <li><Link to={"/become-pos"} title="Become POSP" className="highlight">Become POSP</Link></li>
                                            </ul>
                                            <div className="contact-device">
                                                <h5>Contact Us</h5>
                                                <ul className="device-media" >
                                                    <li>
                                                        <Link to={"https://api.whatsapp.com/send?phone=918441990000"} target="_blank">
                                                            <img src={`${process.env.REACT_APP_S3IMAGEBASE_URL}whatsapp_icon.svg`} alt="Connect on Whatsapp" loading="lazy" />
                                                            <span>Whatsapp</span>
                                                        </Link>
                                                    </li>
                                                    <li><Link to={"https://www.google.com/maps/dir/26.867203,75.722622/square+insurance/@26.8950654,75.6916231,12z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x396db4018eb32223:0xf25b0c4bdcad56be!2m2!1d75.8046781!2d26.9145286"}>
                                                        <img src={`${process.env.REACT_APP_S3IMAGEBASE_URL}menu_location.svg`} alt="Head Office" loading="lazy" />
                                                        <span>Head Office</span>
                                                    </Link>
                                                    </li>
                                                    <li><Link to={"/"}>
                                                        <img src={`${process.env.REACT_APP_S3IMAGEBASE_URL}support_call_ic.svg`} alt="Request a callback" loading="lazy" />
                                                        <span>Request a Callback</span>
                                                    </Link>
                                                    </li>
                                                </ul>
                                                <div className="support_call_number">
                                                    <Link to={"tel:1800-120-5430"}><img src={`${process.env.REACT_APP_S3IMAGEBASE_URL}telephone_icon.svg`} alt="Service" loading="lazy" /><span>Service: 1800-120-5430</span></Link>
                                                    <Link to={"tel:+919783391777"}><img src={`${process.env.REACT_APP_S3IMAGEBASE_URL}support_call_ic.svg`} alt="Posp Support" loading="lazy" /><span>POSP Support: +919783391777</span></Link>
                                                    <Link to={"tel:+919783309475"}><img src={`${process.env.REACT_APP_S3IMAGEBASE_URL}support_call_ic.svg`} alt="Renewal" loading="lazy" /><span>Renewal: +919783309475</span></Link>
                                                </div>
                                                <div className="header_footer_menu">
                                                    <Link to={"https://apps.apple.com/in/app/square-insurance/id1549182208"}><img src={`${process.env.REACT_APP_S3IMAGEBASE_URL}ios-button.png`} alt="app store" loading="lazy" /></Link>
                                                    <Link to={"https://play.google.com/store/apps/details?id=com.square.squareinsurance"}><img src={`${process.env.REACT_APP_S3IMAGEBASE_URL}android-button.png`} alt="play store" loading="lazy" /></Link>
                                                </div>
                                            </div>
                                        </nav>
                                        <div className="login_box">
                                            <button type="button" className="login_popup_cs"  onClick={() => handelLoginPop(true) }>Login</button>
                                            {showLogin && <Login handelLoginPop={handelLoginPop} />}
                                        </div>
                                        <button className="boxes-menu" type="button" onClick={() => handelHeaderDetails(true)}>
                                            <img src={`${process.env.REACT_APP_S3IMAGEBASE_URL}boxes-menu.svg`} />
                                        </button>
                                        {showHeaderDetsils && <OurDetails handelHeaderDetails={handelHeaderDetails} />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
};

export default Header;
