import React, { Suspense,useEffect } from 'react';
import { authSuccess, logout } from './store/auth/action';
import { useDispatch } from 'react-redux';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { allRoute } from './util/routes';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import $ from 'jquery';  // Import jQuery
window.jQuery = $;  // Set jQuery globally


/* localStorage.setItem("is_logged",true);
localStorage.setItem("login_name","visnu kumar");
localStorage.setItem("login_type","employee");
localStorage.setItem("login_data",""); */

function App() {

  const dispatch = useDispatch();

  const handleStorageChange = (event) => {
    if (event.key === 'is_logged') {
      // Handle the change in localStorage here
      let is_logged = localStorage.getItem("is_logged");

      if(is_logged){
          let login_name = localStorage.getItem("login_name");
          let login_data = localStorage.getItem("login_data");
          let login_type = localStorage.getItem("login_type");
          dispatch(authSuccess({is_logged : is_logged,login_name : login_name, login_data: login_data,login_type : login_type }));
      }else{
        dispatch(logout());
      }

      /* let userData = localStorage.getItem("user_data");
      if (userData) {
        let userDetail = JSON.parse(userData);
        if (userDetail["is_logged"]) {
          dispatch(authSuccess(userDetail));
        } else {
          dispatch(logout());
        }
      } */
    }
  };

  useEffect(() => {

    let is_logged = localStorage.getItem("is_logged");

    if(is_logged){
        let login_name = localStorage.getItem("login_name");
        let login_data = localStorage.getItem("login_data");
        let login_type = localStorage.getItem("login_type");
        dispatch(authSuccess({is_logged : is_logged,login_name : login_name, login_data: login_data,login_type : login_type }));
    }

    /* let userData = localStorage.getItem("user_data");
    if (userData) {
      let userDetail = JSON.parse(userData);
      if (userDetail["is_logged"]) {
        dispatch(authSuccess(userDetail));
      }
    } */

    // Add event listener for localStorage changes
    window.addEventListener('storage', handleStorageChange);

    return () => {
      // Clean up the event listener when component unmounts
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
      <Suspense fallback={<div></div>}>
        <Routes>
          {allRoute.map((routeData, index) => ( 
            <Route
             key={index}
             exact={routeData.exact}
             path={routeData.path}
             element={
              <routeData.layout>
                <routeData.component type={routeData?.type} />
              </routeData.layout>
              }
             /* render={(props) => (
               <routeData.layout>
                 <routeData.component {...props} />
               </routeData.layout>
             )} */
           />
          ))}
        </Routes>
        <ToastContainer position="top-right" autoClose={3000} />
        {/* <div>
          <iframe
              src="https://oneclickit.in/health/payment?id=123"
              style={{ display: 'none' }}
              title="auth-status-iframe"
          ></iframe>
        </div> */}
      </Suspense>
  );
}

export default App;
