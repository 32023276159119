import { React } from 'react'
import { Link } from 'react-router-dom';

const Login = (props) => {
    const { handelLoginPop } = props;
    const firstSegment = window.location.pathname.split('/')[1];
    return (
        <>
            <div className="main_custom_popup" id="login_popup_main">
                <div className="main_custom_popup_inner">
                    <div className="main_custom_popup_header">
                        <div className="header_title"></div>
                        <div className="close_btn_pop" onClick={() => handelLoginPop(false)}><img src={`${process.env.REACT_APP_S3IMAGEBASE_URL}close.svg`} alt="Close Button" title="Close Button" /></div>
                    </div>
                    <div className="main_custom_popup_inner_body">
                        <div className="login_popup_data">
                            <div className="image_text_box">
                                <div className="img_box_login">
                                    <img src={`${process.env.REACT_APP_S3IMAGEBASE_URL}login_popup_banner.svg`} alt="Getting In Touch" title="Getting In Touch" />
                                    <div className="login_text_title">Getting In Touch<br />With Us Is Easy</div>
                                </div>
                                <div className="login_details">
                                    {firstSegment === 'employee' ? (
                                        <>
                                            <Link to={`${process.env.REACT_APP_CRMLogin_URL}`} target="_blank">Employee Login</Link>
                                            <Link to={`${process.env.REACT_APP_EHRMSLogin_URL}`} target="_blank">eHrms Login</Link>
                                        </>
                                    ) : (
                                        <>
                                            <Link to={`${process.env.REACT_APP_UserLogin_URL}`} target="_blank">Customer</Link>
                                            <Link to={`${process.env.REACT_APP_AgentLogin_URL}`} target="_blank">POSP</Link>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}
export default Login