import { React } from 'react'
import { Link } from 'react-router-dom';

const OurDetails = (props) => {
    const { handelHeaderDetails } = props;

    return (
        <>
            <div className="header_sidebar active" id="mainHeaderSidebae">
                <div className='headerbarInner'>
                    <div className="sd_header">
                        <Link to={"/"}>
                            <img src={`${process.env.REACT_APP_S3IMAGEBASE_URL}square_full_logo.svg`} alt="Square Insurance - Logo" />
                        </Link>
                        <button type="button" className="btn-close" onClick={() => handelHeaderDetails(false)} aria-label="Close"></button>
                    </div>
                    <div className="sd_body">
                        <ul className="canvas-ul">
                            <li><Link to={"/company-profile"}>About Us</Link></li>
                            <li><Link to={"/blog"}>News</Link></li>
                            <li><Link to={"javascript:void(0)"}>Download Policy</Link></li>
                        </ul>
                        <div className="offcanvas-device">
                            <div className="contact-device">
                                <h5>Contact Us</h5>
                                <ul className="device-media">
                                    <li>
                                        <Link to="https://api.whatsapp.com/send?phone=918441990000" target="_blank">
                                            <img src={`${process.env.REACT_APP_S3IMAGEBASE_URL}whatsapp_icon.svg`} alt="Connect on Whatsapp" />
                                            <span>Connect on<br /> Whatsapp </span>
                                        </Link>
                                    </li>
                                    <li><Link to="https://www.google.com/maps/dir/26.867203,75.722622/square+insurance/@26.8950654,75.6916231,12z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x396db4018eb32223:0xf25b0c4bdcad56be!2m2!1d75.8046781!2d26.9145286">
                                        <img src={`${process.env.REACT_APP_S3IMAGEBASE_URL}menu_location.svg`} alt="Head Office" />
                                        <span>Head Office</span>
                                    </Link>
                                    </li>
                                    <li>
                                        <Link to={"tel:18001205430"}>
                                            <img src={`${process.env.REACT_APP_S3IMAGEBASE_URL}support_call_ic.svg`} alt="Request a callback" />
                                            <span>Request a <br />callback</span>
                                        </Link>
                                    </li>
                                </ul>
                                <div className="support_call_number">
                                    <Link to={"tel:18001205430"}><img src={`${process.env.REACT_APP_S3IMAGEBASE_URL}telephone_icon.svg`} alt="Service" /><span>Service: 1800-120-5430</span></Link>
                                    <Link to={"tel:+919783391777"}><img src={`${process.env.REACT_APP_S3IMAGEBASE_URL}support_call_ic.svg`} alt="Posp Support" /><span>POSP Support: +919783391777</span></Link>
                                    <Link to={"tel:+919783309475"}><img src={`${process.env.REACT_APP_S3IMAGEBASE_URL}support_call_ic.svg`} alt="Renewal" /><span>Renewal: +919783309475</span></Link>
                                    <Link to={"tel:+918905988715"}><img src={`${process.env.REACT_APP_S3IMAGEBASE_URL}support_call_ic.svg`} alt="Renewal" /><span>Tech Support: +918905988715</span></Link>
                                    <Link to={"mailto:info@squareinsurance.in"}><img src={`${process.env.REACT_APP_S3IMAGEBASE_URL}mail.svg`} alt="Mail" /><span>info@squareinsurance.in</span></Link>
                                </div>

                                <div className="header_footer_menu">
                                    <Link to={"https://apps.apple.com/in/app/square-insurance/id1549182208"}><img src={`${process.env.REACT_APP_S3IMAGEBASE_URL}ios-button.png`} alt="app store" /></Link>
                                    <Link to={"https://play.google.com/store/apps/details?id=com.square.squareinsurance"}><img src={`${process.env.REACT_APP_S3IMAGEBASE_URL}android-button.png`} alt="play store" /></Link>
                                </div>
                            </div>
                        </div>
                        <div className="social_media">
                            <h3>Follow Us</h3>
                            <span>
                                <Link to={"https://www.facebook.com/squareinsurance/"} title="facebook" rel="noopener noreferrer nofollow" target="_blank"><img src={`${process.env.REACT_APP_S3IMAGEBASE_URL}social_media/facebook.svg`} alt="facebook" /></Link>
                                <Link to={"https://www.instagram.com/insurancesquare/"} title="instagram" rel="noopener noreferrer nofollow" target="_blank"><img src={`${process.env.REACT_APP_S3IMAGEBASE_URL}social_media/instagram.svg`} alt="instagram" /></Link>
                                <Link to={"https://in.pinterest.com/squareinsurancebroker/"} title="Pinterest" rel="noopener noreferrer nofollow" target="_blank"><img src={`${process.env.REACT_APP_S3IMAGEBASE_URL}social_media/pinterest.svg`} alt="Pinterest" /></Link>
                                <Link to={"https://www.youtube.com/@squareinsurancebrokerspvtl3975"} title="Youtube" rel="noopener noreferrer nofollow" target="_blank"><img src={`${process.env.REACT_APP_S3IMAGEBASE_URL}social_media/youtube.svg`} alt="Youtube" /></Link>
                                <Link to={"https://twitter.com/sibpl_gic"} title="Twitter" rel="noopener noreferrer nofollow" target="_blank"><img src={`${process.env.REACT_APP_S3IMAGEBASE_URL}social_media/twitter.svg`} alt="Twitter" /></Link>
                                <Link to={"https://in.linkedin.com/company/square-insurance-brokers"} title="linkedin" rel="noopener noreferrer nofollow" target="_blank"><img src={`${process.env.REACT_APP_S3IMAGEBASE_URL}social_media/linkedin_logo.svg`} alt="linkedin" /></Link>
                            </span>
                        </div>
                        <p className="mt-2">506, 5th Floor, V-Jai City Point, Ahinsha Circle, C-Scheme, Jaipur (Raj.)-302001</p>
                    </div>
                </div>
            </div>


            {/*             
            <div className="offcanvas" id="offcanvasRightHEADER55602">
                <div className="offcanvas-part">
                    <div className="offcanvas-header">
                        
                    </div>
                    <div className="offcanvas-body">
                   
                        
                    </div>
                </div>
            </div> */}
        </>
    )
}
export default OurDetails