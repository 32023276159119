// import { Placeholder } from "react-select/animated";

export const BASE_URL = process.env.REACT_APP_API_BASE_URL;
//export const BASE_URL = "https://www.squareinsurance.in/testing/new_motor/";
export const BASE_NODE_URL = process.env.REACT_APP_NODE_BASE_URL;
//export const BASE_NODE_URL = "https://node.squareinsurance.in:3000/";
export const COMMON_API_BASE_URL = process.env.REACT_APP_COMMON_API_BASE_URL;
export const BASE_FUTURE_KYC_RETRUN_URL = `${process.env.REACT_APP_API_BASE_URL}future-ckyc-success-redirection/`;
export const BASE_ICICI_MOTOR_KYC_RETRUN_URL = `${process.env.REACT_APP_API_BASE_URL}future-ckyc-success-redirection/`;
export const DATE_FORMAT_DDMMYYYY = "dd/MM/yyyy";
export const PAN_CARD_LIMIT = 49000;
export const S3_BASE_URL = "https://squarebweb-documents.s3.ap-south-1.amazonaws.com/web/";
export const CRYPTO_KEY = "0123456789abcdef0123456789abcdef";
export const CRYPTO_IV = "abcdef9876543210abcdef9876543210";

export const COMMON_PATH = {
    "registration" : "prequotes/get-vehicle-detail",
    "manufacturer" : "prequotes/get-manufacturer-detail",
    "rto" : "prequotes/get-rtos",
    "model" : "prequotes/get-model",
    "fuel" : "prequotes/get-fuel-type",
    "variants" : "prequotes/get-variants",
    "insurer" : "prequotes/get-insurer-company",
    "year" : "prequotes/get-register-year",
    "map_to" : "show-mapping-users",
    "map_user" : "update-mapping-users",
};

export const HEALTH_SURAKSHA_QUE = [
    {"field_name":"question1","question" : "a) Do the person/persons covered, already have Health Suraksha policy of Future Generali?","input_type": "select", "show" : "all", "values" : [{"label":"Yes", "value":"Yes"},{"label":"No", "value":"No"}],"error":"Yes"},
    {"field_name":"question2","question" : "b) Is the person/persons covered, in good health and free from physical and mental disease or infirmity or medical complaints or deformity?","input_type": "select", "show" : "all", "values" : [{"label":"Yes", "value":"Yes"},{"label":"No", "value":"No"}],"error":"No"},
    {"field_name":"question3","question" : "c) . Is the person/persons covered, have at present or in the past had any health complaints, signs or symptoms, or were taking treatment or were hospitalized for any illness?","input_type": "select", "show" : "all", "values" : [{"label":"Yes", "value":"Yes"},{"label":"No", "value":"No"}],"error":"Yes"},
    {"field_name":"question4","question" : "d) .Is the person/persons covered, at present or in the past met with any accident / injury or were hospitalized or taking treatment for any accident injury","input_type": "select", "show" : "all", "values" : [{"label":"Yes", "value":"Yes"},{"label":"No", "value":"No"}],"error":"Yes"},
    {"field_name":"question5","question" : "e) Is the person/persons covered, an Indian resident?","input_type": "select", "show" : "all", "values" : [{"label":"Non Indian", "value":"Yes"},{"label":"Indian", "value":"No"}],"error":"Yes"},
    {"field_name":"question6","question" : "f) For question- Have you or any of the insured person been declined any Health Insurance Policy?","input_type": "select", "show" : "all", "values" : [{"label":"Yes", "value":"Yes"},{"label":"No", "value":"No"}],"error":"Yes"},
    {"field_name":"questionfemale","question" : "Pregnancy Question ) Please confirm if  insured is pregnant ?","input_type": "select", "show" : "Female", "condition" : "18", "values" : [{"label":"Yes", "value":"Yes"},{"label":"No", "value":"No"}],"error":""},
    {"field_name":"questionfemaleans","question" : "Any maternity related complications in present or past ?","input_type": "select", "show" : "Female", "condition" : "18", "values" : [{"label":"No", "value":"No"},{"label":"miscarriage", "value":"miscarriage"},{"label":"gestational diabetes", "value":"gestational diabetes"},{"label":"ectopic pregnancy", "value":"ectopic pregnancy"},{"label":"any other", "value":"any other"}],"error":""},
];

export const HEALTH_ABSOLUTE_QUE = [
    {"field_name":"question1","question" : "Q.1  Is the person covered, at present or in the past had any health complaints or Mental diseases or deformity or had any accident/ injury or is/was taking treatment or hospitalized for any illness, injury or undergone any surgery in the past or going for any planned surgery at present / recent future and/or currently not in good health?","input_type": "select", "show" : "all", "values" : [{"label":"Yes", "value":"Yes"},{"label":"No", "value":"No"}],"error":"Yes"},
    {"field_name":"question2","question" : "Q.2  Do the person covered, already have Health Absolute policy of Future Generali?","input_type": "select", "show" : "all", "values" : [{"label":"Yes", "value":"Yes"},{"label":"No", "value":"No"}],"error":"Yes"},
    {"field_name":"question3","question" : "Q.3  For question- Have you or any of the insured person been declined any Health Insurance Policy?","input_type": "select", "show" : "all", "values" : [{"label":"Yes", "value":"Yes"},{"label":"No", "value":"No"}],"error":"Yes"},
    {"field_name":"Smoke","question" : "Q.1  Do You Smoke?","input_type": "select", "show" : "all", "values" : [{"label":"Yes", "value":"Yes"},{"label":"No", "value":"No"}],"error":""},
    {"field_name":"Tobacco","question" : "Q.2 Do You Tobacco?","input_type": "select", "show" : "all", "values" : [{"label":"Yes", "value":"Yes"},{"label":"No", "value":"No"}],"error":""}
];

export const HEALTH_FUTURE_OTHER_QUE = [
    {"field_name":"questionfemale","question" : "Pregnancy Question ) Please confirm if  insured is pregnant ?","input_type": "select", "show" : "Female", "condition" : "18", "values" : [{"label":"Yes", "value":"Yes"},{"label":"No", "value":"No"}],"error":""},
    {"field_name":"questionfemaleans","question" : "Any maternity related complications in present or past ?","input_type": "select", "show" : "Female", "condition" : "18", "values" : [{"label":"No", "value":"No"},{"label":"miscarriage", "value":"miscarriage"},{"label":"gestational diabetes", "value":"gestational diabetes"},{"label":"ectopic pregnancy", "value":"ectopic pregnancy"},{"label":"any other", "value":"any other"}],"error":""},
    {"field_name":"question1","question" : "a) Is the person already have Health Total policy of Future Generali?","input_type": "select", "show" : "all", "values" : [{"label":"Yes", "value":"Yes"},{"label":"No", "value":"No"}],"error":"Yes"},
    {"field_name":"question2","question" : "b) Is the person in good health and free from physical and mental disease or infirmity or medical complaints or deformity?","input_type": "select", "show" : "all", "values" : [{"label":"Yes", "value":"Yes"},{"label":"No", "value":"No"}],"error":"No"},
    {"field_name":"question3","question" : "c) Is the person have at present or in the past had any health complaints, signs or symptoms, or were taking treatment or were hospitalized for any illness.?","input_type": "select", "show" : "all", "values" : [{"label":"Yes", "value":"Yes"},{"label":"No", "value":"No"}],"error":"Yes"},
    {"field_name":"question4","question" : "d) Is the person at present or in the past met with any accident / injury or were hospitalized or taking treatment for any accident injury?","input_type": "select", "show" : "all", "values" : [{"label":"Yes", "value":"Yes"},{"label":"No", "value":"No"}],"error":"Yes"},
    {"field_name":"question5","question" : "e) Is the person undergone any surgery in the past or going for any planned surgery at present / recent future?","input_type": "select", "show" : "all", "values" : [{"label":"Yes", "value":"Yes"},{"label":"No", "value":"No"}],"error":"Yes"},
    {"field_name":"question6","question" : "f) Is the person Indian resident?","input_type": "select", "show" : "all", "values" : [{"label":"Yes", "value":"Yes"},{"label":"No", "value":"No"}],"error":"No"},
    {"field_name":"question7","question" : "g) Whether any Health Insurance Policy has been declined?","input_type": "select", "show" : "all", "values" : [{"label":"Yes", "value":"Yes"},{"label":"No", "value":"No"}],"error":"Yes"},
    {"field_name":"question8","question" : "h) Do you smoke?","input_type": "select", "show" : "all", "values" : [{"label":"Yes", "value":"Yes"},{"label":"No", "value":"No"}],"error":""},
];


export const HEALTH_NIVABUPA_QUE = [
    {"field_name":"tobacco","question" : "Q.1 Chewable tobacco /Gutkha / Pan Masala.?","input_type": "select", "show" : "all", "values" : [{"label":"Yes", "value":"Yes"},{"label":"No", "value":"No"}],
    "other_option" : [{"field_name" : "tobacco_qty","question" : "Pouches per day","show" : "all", "input_type" : "text", "if":"tobacco", "ans":"Yes", "placeholder" : "", "dataType":"number"}]},

    {"field_name":"alcohol","question" : "Q.2 Alcohol.?","input_type": "select", "show" : "all", "values" : [{"label":"Yes", "value":"Yes"},{"label":"No", "value":"No"}],
    "other_option" : [{"field_name" : "alcohol_day_weekly","question" : "Select", "input_type" : "select", "show" : "all", "values" : [{"label":"Daily", "value":"Daily"},{"label":"Weekly", "value":"Weekly"}], "if":"alcohol", "ans":"Yes","placeholder" : ""},{"field_name" : "alcohol_qty","question" : "ML per week","show" : "all", "input_type" : "text","if":"alcohol", "ans":"Yes","other_if":"alcohol_day_weekly", "other_if_ans":"Weekly","placeholder" : "Alcohol week ml", "dataType":"number" }]},

    {"field_name":"cigarettes","question" : "Q.3 Cigarettes / Bidi / Cigar.?","input_type": "select", "show" : "all", "values" : [{"label":"Yes", "value":"Yes"},{"label":"No", "value":"No"}],
    "other_option" : [{"field_name" : "cigarettes_qty","question" : "Consumption per day","show" : "all", "input_type" : "text","if":"cigarettes", "ans":"Yes","placeholder" : "Cigarettes / Bidi / Cigar per day","dataType":"number" }]},

    {"field_name":"q1","question" : "Q.1 Other than common cold, flu, infections, minor injury or other minor ailments; has the Applicant ever been diagnosed with any disease and / or hospitalized for more than 5 days and / or undergone / advised to undergo any surgical procedures and / or taken any medication/ had any symptoms for more than 14 days? Medication is including but not limited to inhalers, injections, oral drugs and external medical applications on body parts.?","input_type": "select", "show" : "all", "values" : [{"label":"Yes", "value":"Yes"},{"label":"No", "value":"No"}],
    "other_option" : [{"field_name" : "q1_treatment","question" : "Treatment","show" : "all", "input_type" : "text","if":"q1", "ans":"Yes","placeholder" : "Treatment" },
    {"field_name" : "q1_dosage","question" : "Dosage","show" : "all", "input_type" : "text","if":"q1", "ans":"Yes","placeholder" : "Dosage" ,"dataType":"number"},
    {"field_name" : "q1_outcome_dosage","question" : "Outcome Treatment","show" : "all", "input_type" : "text","if":"q1", "ans":"Yes","placeholder" : "Outcome Treatment" },
    {"field_name" : "q1_duration_condition","question" : "Duration Condition","show" : "all", "input_type" : "text","if":"q1", "ans":"Yes","placeholder" : "Duration Condition" },
    {"field_name" : "q1_doctor_hospital_details","question" : "Doctor & Hospital Details","show" : "all", "input_type" : "text","if":"q1", "ans":"Yes","placeholder" : "Doctor & Hospital Details" },
    {"field_name" : "q1_symptoms","question" : "Symptoms","show" : "all", "input_type" : "text","if":"q1", "ans":"Yes","placeholder" : "Symptoms" },
    {"field_name" : "q1_symptoms_date","question" : "Symptoms Date","show" : "all", "input_type" : "date","if":"q1", "ans":"Yes","placeholder" : "Symptoms Date" }]},

    {"field_name":"q2","question" : "Q.2 Has the Applicant ever had adverse findings to any diagnostic tests or investigations related to Thyroid Profile, Lipid Profile, Treadmill test, Angiography, Echocardiography, Endoscopy, Ultrasound, CT Scan, MRI, Biopsy and FNAC?","input_type": "select", "show" : "all", "values" : [{"label":"Yes", "value":"Yes"},{"label":"No", "value":"No"}],
    "other_option" : [{"field_name" : "q2_treatment","question" : "Treatment","show" : "all", "input_type" : "text","if":"q2", "ans":"Yes","placeholder" : "Treatment" },
    {"field_name" : "q2_dosage","question" : "Dosage","show" : "all", "input_type" : "text","if":"q2", "ans":"Yes","placeholder" : "Dosage","dataType":"number" },
    {"field_name" : "q2_outcome_dosage","question" : "Outcome Treatment","show" : "all", "input_type" : "text","if":"q2", "ans":"Yes","placeholder" : "Outcome Treatment" },
    {"field_name" : "q2_duration_condition","question" : "Duration Condition","show" : "all", "input_type" : "text","if":"q2", "ans":"Yes","placeholder" : "Duration Condition" },
    {"field_name" : "q2_doctor_hospital_details","question" : "Doctor & Hospital Details","show" : "all", "input_type" : "text","if":"q2", "ans":"Yes","placeholder" : "Doctor & Hospital Details" },
    {"field_name" : "q2_symptoms","question" : "Symptoms","show" : "all", "input_type" : "text","if":"q2", "ans":"Yes","placeholder" : "Symptoms" },
    {"field_name" : "q2_symptoms_date","question" : "Symptoms Date","show" : "all", "input_type" : "date","if":"q2", "ans":"Yes","placeholder" : "Symptoms Date" }]},

    {"field_name":"q3","question" : "Q.3 Does the Applicant have diabetes or pre-diabetes or has he/she EVER had high blood sugar?","input_type": "select", "show" : "all", "values" : [{"label":"Yes", "value":"Yes"},{"label":"No", "value":"No"}],
    "other_option" : [{"field_name" : "q3_treatment","question" : "Treatment","show" : "all", "input_type" : "text","if":"q3", "ans":"Yes","placeholder" : "Treatment" },
    {"field_name" : "q3_dosage","question" : "Dosage","show" : "all", "input_type" : "text","if":"q3", "ans":"Yes","placeholder" : "Dosage","dataType":"number" },
    {"field_name" : "q3_outcome_dosage","question" : "Outcome Treatment","show" : "all", "input_type" : "text","if":"q3", "ans":"Yes","placeholder" : "Outcome Treatment" },
    {"field_name" : "q3_duration_condition","question" : "Duration Condition","show" : "all", "input_type" : "text","if":"q3", "ans":"Yes","placeholder" : "Duration Condition" },
    {"field_name" : "q3_doctor_hospital_details","question" : "Doctor & Hospital Details","show" : "all", "input_type" : "text","if":"q3", "ans":"Yes","placeholder" : "Doctor & Hospital Details" },
    {"field_name" : "q3_symptoms","question" : "Symptoms","show" : "all", "input_type" : "text","if":"q3", "ans":"Yes","placeholder" : "Symptoms" },
    {"field_name" : "q3_symptoms_date","question" : "Symptoms Date","show" : "all", "input_type" : "date","if":"q3", "ans":"Yes","placeholder" : "Symptoms Date" },
    {"field_name" : "q3_blood_sugar_yes_no","question" : "Blood Suger", "input_type" : "select", "show" : "all", "values" : [{"label":"Yes", "value":"Yes"},{"label":"No", "value":"No"}], "if":"q3", "ans":"Yes","placeholder" : "", "child" : "true", "other_option": [{"field_name" : "q3_Hba1c","question" : "Hba1c Level","show" : "all", "input_type" : "text","if":"q3_blood_sugar_yes_no", "ans":"Yes","placeholder" : "Hba1c Level" }]}
    ]},

    {"field_name":"q4","question" : "Q.4 Does the Applicant have Hypertension or High Blood Pressure?","input_type": "select", "show" : "all", "values" : [{"label":"Yes", "value":"Yes"},{"label":"No", "value":"No"}],
    "other_option" : [{"field_name" : "q4_treatment","question" : "Treatment","show" : "all", "input_type" : "text","if":"q4", "ans":"Yes","placeholder" : "Treatment" },
    {"field_name" : "q4_dosage","question" : "Dosage","show" : "all", "input_type" : "text","if":"q4", "ans":"Yes","placeholder" : "Dosage","dataType":"number" },
    {"field_name" : "q4_outcome_dosage","question" : "Outcome Treatment","show" : "all", "input_type" : "text","if":"q4", "ans":"Yes","placeholder" : "Outcome Treatment" },
    {"field_name" : "q4_duration_condition","question" : "Duration Condition","show" : "all", "input_type" : "text","if":"q4", "ans":"Yes","placeholder" : "Duration Condition" },
    {"field_name" : "q4_doctor_hospital_details","question" : "Doctor & Hospital Details","show" : "all", "input_type" : "text","if":"q4", "ans":"Yes","placeholder" : "Doctor & Hospital Details" },
    {"field_name" : "q4_symptoms","question" : "Symptoms","show" : "all", "input_type" : "text","if":"q4", "ans":"Yes","placeholder" : "Symptoms" },
    {"field_name" : "q4_symptoms_date","question" : "Symptoms Date","show" : "all", "input_type" : "date","if":"q4", "ans":"Yes","placeholder" : "Symptoms Date" },

    {"field_name" : "q4_blood_pressure_yes_no","question" : "Blood Pressure", "input_type" : "select", "show" : "all", "values" : [{"label":"Yes", "value":"Yes"},{"label":"No", "value":"No"}], "if":"q4", "ans":"Yes","placeholder" : "", "child" : "true", "other_option" : [{"field_name" : "q4_systolic","question" : "Systolic","show" : "all", "input_type" : "text","if":"q4_blood_pressure_yes_no", "ans":"Yes","placeholder" : "Systolic","dataType":"number" },{"field_name" : "q4_diastolic","question" : "Diastolic","show" : "all", "input_type" : "text","if":"q4_blood_pressure_yes_no", "ans":"Yes","placeholder" : "Diastolic","dataType":"number" }]} ]},
    

    {"field_name":"q5","question" : "Q.5 Has the Applicant ever been diagnosed or treated for any genetic / hereditary disorders or HIV / AIDS?","input_type": "select", "show" : "all", "values" : [{"label":"Yes", "value":"Yes"},{"label":"No", "value":"No"}],
    "other_option" : [{"field_name" : "q5_treatment","question" : "Treatment","show" : "all", "input_type" : "text","if":"q5", "ans":"Yes","placeholder" : "Treatment" },
    {"field_name" : "q5_dosage","question" : "Dosage","show" : "all", "input_type" : "text","if":"q5", "ans":"Yes","placeholder" : "Dosage","dataType":"number" },
    {"field_name" : "q5_outcome_dosage","question" : "Outcome Treatment","show" : "all", "input_type" : "text","if":"q5", "ans":"Yes","placeholder" : "Outcome Treatment" },
    {"field_name" : "q5_duration_condition","question" : "Duration Condition","show" : "all", "input_type" : "text","if":"q5", "ans":"Yes","placeholder" : "Duration Condition" },
    {"field_name" : "q5_doctor_hospital_details","question" : "Doctor & Hospital Details","show" : "all", "input_type" : "text","if":"q5", "ans":"Yes","placeholder" : "Doctor & Hospital Details" },
    {"field_name" : "q5_symptoms","question" : "Symptoms","show" : "all", "input_type" : "text","if":"q5", "ans":"Yes","placeholder" : "Symptoms" },
    {"field_name" : "q5_symptoms_date","question" : "Symptoms Date","show" : "all", "input_type" : "date","if":"q5", "ans":"Yes","placeholder" : "Symptoms Date" }]},


    
    {"field_name":"q6","question" : "Q.6 Has the Applicant ever been diagnosed or treated for any mental/ psychiatric disorders?","input_type": "select", "show" : "all", "values" : [{"label":"Yes", "value":"Yes"},{"label":"No", "value":"No"}],
    "other_option" : [{"field_name" : "q6_treatment","question" : "Treatment","show" : "all", "input_type" : "text","if":"q6", "ans":"Yes","placeholder" : "Treatment" },
    {"field_name" : "q6_dosage","question" : "Dosage","show" : "all", "input_type" : "text","if":"q6", "ans":"Yes","placeholder" : "Dosage","dataType":"number" },
    {"field_name" : "q6_outcome_dosage","question" : "Outcome Treatment","show" : "all", "input_type" : "text","if":"q6", "ans":"Yes","placeholder" : "Outcome Treatment" },
    {"field_name" : "q6_duration_condition","question" : "Duration Condition","show" : "all", "input_type" : "text","if":"q6", "ans":"Yes","placeholder" : "Duration Condition" },
    {"field_name" : "q6_doctor_hospital_details","question" : "Doctor & Hospital Details","show" : "all", "input_type" : "text","if":"q6", "ans":"Yes","placeholder" : "Doctor & Hospital Details" },
    {"field_name" : "q6_symptoms","question" : "Symptoms","show" : "all", "input_type" : "text","if":"q6", "ans":"Yes","placeholder" : "Symptoms" },
    {"field_name" : "q6_symptoms_date","question" : "Symptoms Date","show" : "all", "input_type" : "date","if":"q6", "ans":"Yes","placeholder" : "Symptoms Date" }]},
    
    {"field_name":"q7","question" : "Q.7 Has any proposal for life, health, hospital daily cash or critical illness insurance on the life of the Applicant ever been declined, postponed, loaded or subjected to any special conditions such as exclusions by any insurance company?","input_type": "select", "show" : "all", "values" : [{"label":"Yes", "value":"Yes"},{"label":"No", "value":"No"}],
   },
];


export const HEALTH_CARE_QUE = [
    {
        "field_name":"LifeStyle_Smoke_YesNo",
        "question" : "Q.1 Do You smoke, consume alcohol, or chew tobacco, ghutka or paan or use any recreational drugs?",
        "input_type": "select", 
        "show" : "all", 
        "values" : [{"label":"Yes", "value":"Yes"},{"label":"No", "value":"No"}], 
        "defaultValue" : "No",
        "other_option" : [
            {
                "field_name" : "LifeStyle_Smoke_Desc",
                "question" : "Description",
                "show" : "all", 
                "input_type" : "textarea", 
                "if":"LifeStyle_Smoke_YesNo", 
                "ans":"Yes", 
                "placeholder" : "Enter Smoke Description", 
                "defaultValue" : "" 
            },
            {
                "field_name" : "LifeStyle_Smoke_Year",
                "question" : "Select Year", 
                "input_type" : "year", 
                "show" : "all", 
                "values" : "", 
                "if":"LifeStyle_Smoke_YesNo", 
                "ans":"Yes",
                "placeholder" : "Year", 
                "defaultValue" : ""
            },
            {
                "field_name" : "LifeStyle_Smoke_Month",
                "question" : "Select Month", 
                "input_type" : "month", 
                "show" : "all", 
                "values" :"", 
                "if":"LifeStyle_Smoke_YesNo", 
                "ans":"Yes","placeholder" : 
                "Month","defaultValue" : ""
            }
        ]
    },

    {
        "field_name":"Medical_Exists_YesNo",
        "question" : "Q.1 Does Any insured has PED?",
        "input_type": "select", 
        "show" : "all", 
        "values" : [{"label":"Yes", "value":"Yes"},{"label":"No", "value":"No"}], 
        "defaultValue" : "No",
        "other_option" : [
            {
                "field_name" : "Medical_Cancer_YesNo",
                "question" : "Q.1.1 Cancer or Tumor of any kind?", 
                "input_type" : "select", 
                "show" : "all", 
                "values" : [{"label":"Yes", "value":"Yes"},{"label":"No", "value":"No"}],"if":"Medical_Exists_YesNo", 
                "ans":"Yes",
                "placeholder" : "", 
                "colspan" : "8", 
                "defaultValue" : "No", 
                "other_option" : [
                    {
                        "field_name" : "Medical_Cancer_Year",
                        "question" : "Select Year", 
                        "input_type" : "year", 
                        "show" : "all", 
                        "values" : "", 
                        "if":"Medical_Cancer_YesNo", 
                        "ans":"Yes",
                        "placeholder" : "Year", 
                        "defaultValue" : ""
                    },
                    {
                        "field_name" : "Medical_Cancer_Month",
                        "question" : "Select Month", 
                        "input_type" : "month", 
                        "show" : "all", 
                        "values" :"", 
                        "if":"Medical_Cancer_YesNo", 
                        "ans":"Yes",
                        "placeholder" : "Month", 
                        "defaultValue" : ""
                    }
                ]
            },

            {
                "field_name" : "Medical_Cardiac_YesNo",
                "question" : "Q.1.2 Any heart related or circulatory system disorders?", 
                "input_type" : "select", 
                "show" : "all", 
                "values" : [{"label":"Yes", "value":"Yes"},{"label":"No", "value":"No"}],"if":"Medical_Exists_YesNo", 
                "ans":"Yes",
                "placeholder" : "",
                "colspan" : "8", 
                "defaultValue" : "No", 
                "other_option" : [
                    {
                        "field_name" : "Medical_Cardiac_Year",
                        "question" : "Select Year", 
                        "input_type" : "year", 
                        "show" : "all", 
                        "values" : "", 
                        "if":"Medical_Cardiac_YesNo", 
                        "ans":"Yes",
                        "placeholder" : "Year", 
                        "defaultValue" : ""
                    },
                    {
                        "field_name" : "Medical_Cardiac_Month",
                        "question" : "Select Month", 
                        "input_type" : "month", 
                        "show" : "all", 
                        "values" :"", 
                        "if":"Medical_Cardiac_YesNo", 
                        "ans":"Yes",
                        "placeholder" : "Month", 
                        "defaultValue" : ""
                    }
                ]
            },

            {
                "field_name" : "Medical_HyperTension_YesNo",
                "question" : "Q.1.3 Hypertension/High Blood Pressure/Cholesterol disorder?", 
                "input_type" : "select", 
                "show" : "all", 
                "values" : [{"label":"Yes", "value":"Yes"},{"label":"No", "value":"No"}],"if":"Medical_Exists_YesNo", 
                "ans":"Yes",
                "placeholder" : "", 
                "colspan" : "8", 
                "defaultValue" : "No", 
                "other_option" : [
                    {
                        "field_name" : "Medical_HyperTension_Year",
                        "question" : "Select Year", 
                        "input_type" : "year", 
                        "show" : "all", 
                        "values" : "", 
                        "if":"Medical_HyperTension_YesNo", 
                        "ans":"Yes",
                        "placeholder" : "Year",
                        "defaultValue" : ""
                    },
                    {
                        "field_name" : "Medical_HyperTension_Month",
                        "question" : "Select Month", 
                        "input_type" : "month", 
                        "show" : "all", 
                        "values" :"", 
                        "if":"Medical_HyperTension_YesNo", 
                        "ans":"Yes",
                        "placeholder" : "Month", 
                        "defaultValue" : ""
                    }
                ]
            },

            {
                "field_name" : "Medical_Respiratory_YesNo",
                "question" : "Q.1.4 Breathing/Respiratory issues(E.g.TB,Asthma,etc.)?", 
                "input_type" : "select", 
                "show" : "all", 
                "values" : [{"label":"Yes", "value":"Yes"},{"label":"No", "value":"No"}],"if":"Medical_Exists_YesNo", 
                "ans":"Yes",
                "placeholder" : "", 
                "colspan" : "8", 
                "defaultValue" : "No",
                "other_option" : [
                    {
                        "field_name" : "Medical_Respiratory_Year",
                        "question" : "Select Year", 
                        "input_type" : "year", 
                        "show" : "all", 
                        "values" : "", 
                        "if":"Medical_Respiratory_YesNo", 
                        "ans":"Yes",
                        "placeholder" : "Year", 
                        "defaultValue" : ""
                    },
                    {
                        "field_name" : "Medical_Respiratory_Month",
                        "question" : "Select Month", 
                        "input_type" : "month", 
                        "show" : "all", 
                        "values" :"", 
                        "if":"Medical_Respiratory_YesNo", 
                        "ans":"Yes",
                        "placeholder" : "Month", 
                        "defaultValue" : ""
                    }
                ]
            },

            {
                "field_name": "Medical_Endocrine_YesNo",
                "question": "Q.1.5 Endocrine disorders (E.g. Thyroid related disorders, etc)?",
                "input_type": "select",
                "show": "all",
                "values": [
                    {"label": "Yes", "value": "Yes"},
                    {"label": "No", "value": "No"}
                ],
                "if": "Medical_Exists_YesNo",
                "ans": "Yes",
                "placeholder": "",
                "colspan": "8",
                "defaultValue": "No",
                "other_option": [
                    {
                        "field_name": "Medical_Endocrine_Year",
                        "question": "Select Year",
                        "input_type": "year",
                        "show": "all",
                        "values": "",
                        "if": "Medical_Endocrine_YesNo",
                        "ans": "Yes",
                        "placeholder": "Year",
                        "defaultValue": ""
                    },
                    {
                        "field_name": "Medical_Endocrine_Month",
                        "question": "Select Month",
                        "input_type": "month",
                        "show": "all",
                        "values": "",
                        "if": "Medical_Endocrine_YesNo",
                        "ans": "Yes",
                        "placeholder": "Month",
                        "defaultValue": ""
                    }
                ]
            },
              
            {
                "field_name": "Medical_Diabetes_YesNo",
                "question": "Q.1.6 Diabetes/High blood sugar?",
                "input_type": "select",
                "show": "all",
                "values": [
                    {"label": "Yes", "value": "Yes"},
                    {"label": "No", "value": "No"}
                ],
                "if": "Medical_Exists_YesNo",
                "ans": "Yes",
                "placeholder": "",
                "colspan": "8",
                "defaultValue": "No",
                "other_option": [
                    {
                        "field_name": "Medical_Diabetes_Year",
                        "question": "Select Year",
                        "input_type": "year",
                        "show": "all",
                        "values": "",
                        "if": "Medical_Diabetes_YesNo",
                        "ans": "Yes",
                        "placeholder": "Year",
                        "defaultValue": ""
                    },
                    {
                        "field_name": "Medical_Diabetes_Month",
                        "question": "Select Month",
                        "input_type": "month",
                        "show": "all",
                        "values": "",
                        "if": "Medical_Diabetes_YesNo",
                        "ans": "Yes",
                        "placeholder": "Month",
                        "defaultValue": ""
                    }
                ]
            },

            {
                "field_name": "Medical_Paralysis_YesNo",
                "question": "Q.7 Muscles or Nervous system related disorder or Stroke/Epilepsy/Paralysis of other brain related disorders?",
                "input_type": "select",
                "show": "all",
                "values": [
                    {"label": "Yes", "value": "Yes"},
                    {"label": "No", "value": "No"}
                ],
                "if": "Medical_Exists_YesNo",
                "ans": "Yes",
                "placeholder": "",
                "colspan": "8",
                "defaultValue": "No",
                "other_option": [
                    {
                        "field_name": "Medical_Paralysis_Year",
                        "question": "Select Year",
                        "input_type": "year",
                        "show": "all",
                        "values": "",
                        "if": "Medical_Paralysis_YesNo",
                        "ans": "Yes",
                        "placeholder": "Year",
                        "defaultValue": ""
                    },
                    {
                        "field_name": "Medical_Paralysis_Month",
                        "question": "Select Month",
                        "input_type": "month",
                        "show": "all",
                        "values": "",
                        "if": "Medical_Paralysis_YesNo",
                        "ans": "Yes",
                        "placeholder": "Month",
                        "defaultValue": ""
                    }
                ]
            },

            {
                "field_name": "Medical_Liver_YesNo",
                "question": "Q.1.8 Liver/gallbladder or any other Gastro-Intestinal Disease?",
                "input_type": "select",
                "show": "all",
                "values": [
                    {"label": "Yes", "value": "Yes"},
                    {"label": "No", "value": "No"}
                ],
                "if": "Medical_Exists_YesNo",
                "ans": "Yes",
                "placeholder": "",
                "colspan": "8",
                "defaultValue": "No",
                "other_option": [
                    {
                        "field_name": "Medical_Liver_Year",
                        "question": "Select Year",
                        "input_type": "year",
                        "show": "all",
                        "values": "",
                        "if": "Medical_Liver_YesNo",
                        "ans": "Yes",
                        "placeholder": "Year",
                        "defaultValue": ""
                    },
                    {
                        "field_name": "Medical_Liver_Month",
                        "question": "Select Month",
                        "input_type": "month",
                        "show": "all",
                        "values": "",
                        "if": "Medical_Liver_YesNo",
                        "ans": "Yes",
                        "placeholder": "Month",
                        "defaultValue": ""
                    }
                ]
            },

            {
                "field_name": "Medical_Kidney_YesNo",
                "question": "Q.1.9 Kidney failure/Stone/Dialysis/Gynaecological/Prostate disease?",
                "input_type": "select",
                "show": "all",
                "values": [
                    {"label": "Yes", "value": "Yes"},
                    {"label": "No", "value": "No"}
                ],
                "if": "Medical_Exists_YesNo",
                "ans": "Yes",
                "placeholder": "",
                "colspan": "8",
                "defaultValue": "No",
                "other_option": [
                    {
                        "field_name": "Medical_Kidney_Year",
                        "question": "Select Year",
                        "input_type": "year",
                        "show": "all",
                        "values": "",
                        "if": "Medical_Kidney_YesNo",
                        "ans": "Yes",
                        "placeholder": "Year",
                        "defaultValue": ""
                    },
                    {
                        "field_name": "Medical_Kidney_Month",
                        "question": "Select Month",
                        "input_type": "month",
                        "show": "all",
                        "values": "",
                        "if": "Medical_Kidney_YesNo",
                        "ans": "Yes",
                        "placeholder": "Month",
                        "defaultValue": ""
                    }
                ]
            },

            {
                "field_name": "Medical_Immune_YesNo",
                "question": "Q.1.10 Auto-immune or Blood related disorders (Rheumatoid arthritis, Thalassemia, etc.)?",
                "input_type": "select",
                "show": "all",
                "values": [
                    {"label": "Yes", "value": "Yes"},
                    {"label": "No", "value": "No"}
                ],
                "if": "Medical_Exists_YesNo",
                "ans": "Yes",
                "placeholder": "",
                "colspan": "8",
                "defaultValue": "No",
                "other_option": [
                    {
                        "field_name": "Medical_Immune_Year",
                        "question": "Select Year",
                        "input_type": "year",
                        "show": "all",
                        "values": "",
                        "if": "Medical_Immune_YesNo",
                        "ans": "Yes",
                        "placeholder": "Year",
                        "defaultValue": ""
                    },
                    {
                        "field_name": "Medical_Immune_Month",
                        "question": "Select Month",
                        "input_type": "month",
                        "show": "all",
                        "values": "",
                        "if": "Medical_Immune_YesNo",
                        "ans": "Yes",
                        "placeholder": "Month",
                        "defaultValue": ""
                    }
                ]
            },

            {
                "field_name": "Medical_Injury_YesNo",
                "question": "Q.1.11 Any other disease/health adversity/injury/condition/treatment not mentioned above?",
                "input_type": "select",
                "show": "all",
                "values": [
                    {"label": "Yes", "value": "Yes"},
                    {"label": "No", "value": "No"}
                ],
                "if": "Medical_Exists_YesNo",
                "ans": "Yes",
                "placeholder": "",
                "colspan": "8",
                "defaultValue": "No",
                "other_option": [
                    {
                        "field_name" : "Medical_Injury_Desc",
                        "question" : "Description",
                        "show" : "all", 
                        "input_type" : "textarea", 
                        "if":"Medical_Injury_YesNo", 
                        "ans":"Yes", 
                        "placeholder" : "Enter other Description", 
                        "defaultValue" : "" 
                    },
                    {
                        "field_name": "Medical_Injury_Year",
                        "question": "Select Year",
                        "input_type": "year",
                        "show": "all",
                        "values": "",
                        "if": "Medical_Injury_YesNo",
                        "ans": "Yes",
                        "placeholder": "Year",
                        "defaultValue": ""
                    },
                    {
                        "field_name": "Medical_Injury_Month",
                        "question": "Select Month",
                        "input_type": "month",
                        "show": "all",
                        "values": "",
                        "if": "Medical_Injury_YesNo",
                        "ans": "Yes",
                        "placeholder": "Month",
                        "defaultValue": ""
                    }
                ]
            },

            {
                "field_name": "Medical_Consulted_YesNo",
                "question": "Q.1.12 Has any of the Proposed to be Insured consulted/taken treatment or recommended to take investigations/medication/surgery other than for childbirth/minor injuries?",
                "input_type": "select",
                "show": "all",
                "values": [
                    {"label": "Yes", "value": "Yes"},
                    {"label": "No", "value": "No"}
                ],
                "if": "Medical_Exists_YesNo",
                "ans": "Yes",
                "placeholder": "",
                "colspan": "8",
                "defaultValue": "No",
                "other_option": [
                    {
                        "field_name": "Medical_Consulted_Year",
                        "question": "Select Year",
                        "input_type": "year",
                        "show": "all",
                        "values": "",
                        "if": "Medical_Consulted_YesNo",
                        "ans": "Yes",
                        "placeholder": "Year",
                        "defaultValue": ""
                    },
                    {
                        "field_name": "Medical_Consulted_Month",
                        "question": "Select Month",
                        "input_type": "month",
                        "show": "all",
                        "values": "",
                        "if": "Medical_Consulted_YesNo",
                        "ans": "Yes",
                        "placeholder": "Month",
                        "defaultValue": ""
                    }
                ]
            },

            {
                "field_name": "Medical_Prolonged_YesNo",
                "question": "Q.1.13 Has any of the Proposed to be Insured been hospitalized or has been under any prolonged treatment for any illness/injury or has undergone surgery other than for childbirth/minor injuries?",
                "input_type": "select",
                "show": "all",
                "values": [
                    {"label": "Yes", "value": "Yes"},
                    {"label": "No", "value": "No"}
                ],
                "if": "Medical_Exists_YesNo",
                "ans": "Yes",
                "placeholder": "",
                "colspan": "8",
                "defaultValue": "No",
                "other_option": [
                    {
                        "field_name": "Medical_Prolonged_Year",
                        "question": "Select Year",
                        "input_type": "year",
                        "show": "all",
                        "values": "",
                        "if": "Medical_Prolonged_YesNo",
                        "ans": "Yes",
                        "placeholder": "Year",
                        "defaultValue": ""
                    },
                    {
                        "field_name": "Medical_Prolonged_Month",
                        "question": "Select Month",
                        "input_type": "month",
                        "show": "all",
                        "values": "",
                        "if": "Medical_Prolonged_YesNo",
                        "ans": "Yes",
                        "placeholder": "Month",
                        "defaultValue": ""
                    }
                ]
            },

            {
                "field_name": "Medical_HIV_YesNo",
                "question": "Q.1.14 HIV/AIDS/STD?",
                "input_type": "select",
                "show": "all",
                "values": [
                    {"label": "Yes", "value": "Yes"},
                    {"label": "No", "value": "No"}
                ],
                "if": "Medical_Exists_YesNo",
                "ans": "Yes",
                "placeholder": "",
                "colspan": "8",
                "defaultValue": "No",
                "other_option": [
                    {
                        "field_name": "Medical_HIV_Year",
                        "question": "Select Year",
                        "input_type": "year",
                        "show": "all",
                        "values": "",
                        "if": "Medical_HIV_YesNo",
                        "ans": "Yes",
                        "placeholder": "Year",
                        "defaultValue": ""
                    },
                    {
                        "field_name": "Medical_HIV_Month",
                        "question": "Select Month",
                        "input_type": "month",
                        "show": "all",
                        "values": "",
                        "if": "Medical_HIV_YesNo",
                        "ans": "Yes",
                        "placeholder": "Month",
                        "defaultValue": ""
                    }
                ]
            },

            {
                "field_name" : "Medical_Congenital_YesNo",
                "question" : "Q.1.15 Congenital Disorder?",
                "input_type" : "select", 
                "show" : "all", 
                "values" : [{"label":"Yes", "value":"Yes"},{"label":"No", "value":"No"}],"if":"Medical_Exists_YesNo", 
                "ans":"Yes",
                "placeholder" : "", 
                "colspan" : "8", 
                "defaultValue" : "No",
                "other_option" : [
                    {
                        "field_name" : "Medical_Congenital_Year",
                        "question" : "Select Year", 
                        "input_type" : "year", 
                        "show" : "all", 
                        "values" : "", 
                        "if":"Medical_Congenital_YesNo", 
                        "ans":"Yes",
                        "placeholder" : "Year", 
                        "defaultValue" : ""
                    },
                    {
                        "field_name" : "Medical_Congenital_Month",
                        "question" : "Select Month", 
                        "input_type" : "month", 
                        "show" : "all", 
                        "values" :"", 
                        "if":"Medical_Congenital_YesNo", 
                        "ans":"Yes",
                        "placeholder" : "Month", 
                        "defaultValue" : ""
                    }
                ]
            }
        ]
    },

    {
        "field_name":"Medical_Exists2_YesNo",
        "question" : "Q.2 Details of previous or existing health insurance?",
        "input_type": "select",
        "show" : "all", 
        "values" : [{"label":"Yes", "value":"Yes"},{"label":"No", "value":"No"}],
        "defaultValue" : "No",
        "other_option" : [
            {
                "field_name" : "Medical_H001_YesNo",
                "question" : "Q.2.1 Is any of the person(s) to be insured, already covered under any other health insurance policy of Religare Health Insurance?", 
                "input_type" : "select", 
                "show" : "all", 
                "values" : [{"label":"Yes", "value":"Yes"},{"label":"No", "value":"No"}],"if":"Medical_Exists2_YesNo", 
                "ans":"Yes",
                "placeholder" : "", 
                "colspan" : "8", 
                "defaultValue" : "No"
            },

            {
                "field_name" : "Medical_H002_YesNo",
                "question" : "Q.2.2 Have any of the person(s) to be insured ever filed a claim with their current/previous insurer?", 
                "input_type" : "select", 
                "show" : "all", 
                "values" : [{"label":"Yes", "value":"Yes"},{"label":"No", "value":"No"}],"if":"Medical_Exists2_YesNo", 
                "ans":"Yes",
                "placeholder" : "", 
                "colspan" : "8", 
                "defaultValue" : "No"
            },

            {
                "field_name" : "Medical_H003_YesNo",
                "question" : "Q.2.3 Has any proposal for Health insurance been declined, cancelled or charged a higher premium?", 
                "input_type" : "select", 
                "show" : "all", 
                "values" : [{"label":"Yes", "value":"Yes"},{"label":"No", "value":"No"}],"if":"Medical_Exists2_YesNo", 
                "ans":"Yes",
                "placeholder" : "", 
                "colspan" : "8", 
                "defaultValue" : "No"
            },

            {
                "field_name" : "Medical_H004_YesNo",
                "question" : "Q.2.4 Is any of the person(s) to be insured, already covered under any other health insurance policy of Religare Health Insurance?", 
                "input_type" : "select", 
                "show" : "all", 
                "values" : [{"label":"Yes", "value":"Yes"},{"label":"No", "value":"No"}],"if":"Medical_Exists2_YesNo", 
                "ans":"Yes",
                "placeholder" : "", 
                "colspan" : "8", 
                "defaultValue" : "No"
            }
        ]
    }
    
];