import {
  AUTH_SUCCESS,
  AUTH_LOGOUT,
  SIDEBAR_TOGGLE,
  LOADING,
  UPDATE_TOKEN,
} from "./actionTypes";

export const authSuccess = (updates) => {
  if (updates.is_logged) {
    const { loggedIn, login_type, login_data } = updates;
    /* localStorage.setItem("login_data", login_data);
    localStorage.setItem("login_type", login_type);
    localStorage.setItem("loggedIn", loggedIn); */
  }
  return {
    type: AUTH_SUCCESS,
    updates,
  };
};

export const sidebarToggle = (updates) => {
  return {
    type: SIDEBAR_TOGGLE,
    updates,
  };
};

export const logout = () => {
  localStorage.removeItem("user_data");
//   toast.success("You are now logged out!");
//   var testObject = {
//     url:window.location.href,
//     time:25
//   };
// // Put the object into storage
//   localStorage.setItem('testObject', JSON.stringify(testObject));
  return {
    type: AUTH_LOGOUT,
  };
};

export const updateLoading = (updates) => {
  return {
    type: LOADING,
    updates,
  };
};

export const updateToken = (updates) => {
  localStorage.setItem("token", updates.token);
  return {
    type: UPDATE_TOKEN,
    updates,
  };
};
